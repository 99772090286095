import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import {
  Box,
  Grid,
  Skeleton,
  Table,
  TableBody,
  Typography,
} from '@mui/material'

import Attributions from './Attributions'
import Item from './Item'
import Mezmo from './Mezmo'
import { UserDocument } from '../../../../../__generated__/graphql'

const STRIPE_CUSTOMER_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://dashboard.stripe.com/test/customers/'
    : 'https://dashboard.stripe.com/customers/'

export default function Details({ id }: { id: string }) {
  const { data, error, loading } = useQuery(UserDocument, {
    variables: { input: { id } },
  })

  const recommendationProgress = useMemo(
    () =>
      data
        ? JSON.stringify(JSON.parse(data.user?.progress), undefined, 2)
        : null,
    [data],
  )

  if (!data?.user) {
    return null
  }
  return (
    <Box sx={{ margin: 1 }}>
      {loading && <Skeleton />}
      {error && <>Error loading user</>}
      {!loading && data && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Table size="small">
              <TableBody>
                <Item label="Registered With">{data.user.registeredWith}</Item>
                <Item label="2FA Method">{data.user.twofaMethod}</Item>
                {data.user.stripeCustomerId && (
                  <Item label="Stripe Customer ID">
                    <a
                      href={`${STRIPE_CUSTOMER_URL}${data.user.stripeCustomerId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {data.user.stripeCustomerId}
                    </a>
                  </Item>
                )}
                <Mezmo
                  anonymousUserIds={data.user.anonymousUserIds}
                  userAccountId={data.user.id}
                />
                <Attributions
                  anonymousUserIds={data.user.anonymousUserIds}
                  incomingAttributions={data.user.attributions}
                  label="Attributions"
                />
                <Attributions
                  anonymousUserIds={data.user.anonymousUserIds}
                  incomingAttributions={data.user.initialAttributions}
                  label="Initial Attributions"
                />
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="div"
              sx={{ fontWeight: 'bold' }}
              variant="subtitle2"
            >
              Recommendation Progress
            </Typography>
            <Box
              sx={{
                background: 'black',
                borderRadius: '0.25rem',
                color: 'lightgreen',
                fontSize: '0.75rem',
                height: '300px',
                overflowY: 'scroll',
              }}
            >
              <Box
                component="pre"
                sx={{ padding: '0.875rem', whiteSpace: 'pre-wrap' }}
              >
                {recommendationProgress}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
