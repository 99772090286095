import React, { PropsWithChildren } from 'react'

import InteractiveElement from './InteractiveElement'
import LogContextProvider from './LogContextProvider'
import ArrowTopRight from '../icons/arrow-top-right.svg'

// TODO: We *should* import a file SidebarLink.scss in this file;
//       however, due to imports, this leads to `background-script.js`
//       importing the CSS, which then gets bundled and causes Chrome
//       to error out. We instead set the styles in
//       RecommendationCardSettingRow and RecommendationCardStepRow.

export default function SidebarLink({
  href,
  children,
}: PropsWithChildren<{ href: string }>) {
  return (
    <LogContextProvider linkUrl={href}>
      <InteractiveElement className="SidebarLink" role="link">
        <a target="_parent" href={href}>
          <span className="link-text">{children}</span>
          <ArrowTopRight className="link-arrow" />
        </a>
      </InteractiveElement>
    </LogContextProvider>
  )
}
