import React from 'react'

import clsx from 'clsx'

import InteractiveElement from '$extensionSrc/components/InteractiveElement'
import CheckboxIndeterminate from '$extensionSrc/icons/checkbox-indeterminate.svg'
import Checkbox from '$extensionSrc/icons/checkbox.svg'

import styles from './ToDoCheckbox.module.scss'

export default function ToDoCheckbox({
  disabled = false,
  isIndeterminate = false,
  isSettingSelected,
  labelId,
  onClick,
}: {
  disabled?: boolean
  isIndeterminate?: boolean
  isSettingSelected: boolean
  labelId: string
  onClick: () => void
}) {
  return (
    <InteractiveElement
      aria-checked={isSettingSelected}
      aria-labelledby={labelId}
      className={styles.toDoCheckbox}
      onClick={onClick}
      role="checkbox"
    >
      {isIndeterminate ? (
        <CheckboxIndeterminate
          aria-label="checkbox indeterminate icon"
          className={clsx(
            styles.icon,
            styles.checked,
            disabled && styles.disabled,
          )}
        />
      ) : (
        <Checkbox
          aria-label="checkbox icon"
          className={clsx(
            styles.icon,
            isSettingSelected && styles.checked,
            disabled && styles.disabled,
          )}
        />
      )}
    </InteractiveElement>
  )
}
