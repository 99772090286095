import React, { ReactNode, RefObject } from 'react'

import { LogPropsContext } from '$extensionSrc/components/LogContextProvider'
import { track } from '$extensionSrc/metrics/track'
import { Event, Interaction } from '$extensionSrc/metrics/types'

type Props = {
  children: ReactNode
  eventName: string
  eventProps: any
}

type State = {
  isInViewport: boolean
}

class ImpressionLogger extends React.Component<Props, State> {
  combinedProps: any

  hasImpressionAlreadyBeenLogged: boolean

  logDOMElementRef: RefObject<any>

  loggedPropsJson: string

  observer: IntersectionObserver

  constructor(props: Props) {
    super(props)

    this.logDOMElementRef = React.createRef()
    this.state = { isInViewport: false }
    this.hasImpressionAlreadyBeenLogged = false
    this.loggedPropsJson = JSON.stringify({})
    this.observerCallback = this.observerCallback.bind(this)
  }

  componentDidMount() {
    this.setupObserver()
  }

  componentDidUpdate() {
    const { eventName, eventProps } = this.props
    if (this.loggedPropsJson !== JSON.stringify(eventProps)) {
      this.hasImpressionAlreadyBeenLogged = false
    }

    const { isInViewport } = this.state
    if (isInViewport && !this.hasImpressionAlreadyBeenLogged) {
      const eventOrInteraction: Event | Interaction = {
        name: eventName,
        properties: this.combinedProps,
      } as Event | Interaction
      track(eventOrInteraction)
      this.hasImpressionAlreadyBeenLogged = true
      this.loggedPropsJson = JSON.stringify(eventProps)
    }
  }

  setupObserver() {
    this.observer = new IntersectionObserver(this.observerCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    })

    const wrappedDOMElements: HTMLElement[] =
      this.logDOMElementRef?.current?.childNodes || []
    const firstVisibleElement: HTMLElement | undefined = Array.from(
      wrappedDOMElements,
    ).find(
      (el: HTMLElement) =>
        el.offsetParent !== null ||
        window.getComputedStyle(el).getPropertyValue('display') !== 'none',
    )

    if (firstVisibleElement) {
      this.observer.observe(firstVisibleElement)
    }
  }

  observerCallback(entries) {
    const { isInViewport } = this.state
    const entry = entries[0]

    if (entry !== undefined && isInViewport !== entry.isIntersecting) {
      this.setState(() => ({
        isInViewport: entry.isIntersecting,
      }))
    }
  }

  render() {
    const { children, eventName, eventProps } = this.props
    return (
      <LogPropsContext.Consumer>
        {(consumedProps: any) => {
          this.combinedProps = { ...consumedProps, eventName, ...eventProps }

          return (
            <div style={{ display: 'contents' }} ref={this.logDOMElementRef}>
              {children}
            </div>
          )
        }}
      </LogPropsContext.Consumer>
    )
  }
}

export default ImpressionLogger
