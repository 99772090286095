import React, { ReactNode, useMemo } from 'react'

import clsx from 'clsx'

import CheckmarkCalendarIcon from '$extensionSrc/icons/checkmark-calendar.svg'
import PeopleIcon from '$extensionSrc/icons/people-icon.svg'

import styles from './ProtipBanner.module.scss'

export default function ProtipBanner({
  children,
  hideIcon,
  iconType,
  variant,
}: {
  children: ReactNode
  hideIcon?: boolean
  iconType?: 'calendar' | 'people' | undefined
  variant?: 'blue'
}) {
  const icon: ReactNode = useMemo(() => {
    switch (iconType) {
      case 'calendar':
        return <CheckmarkCalendarIcon />
      case 'people':
        return <PeopleIcon />
      default:
        return null
    }
  }, [iconType])

  return (
    <div
      className={clsx(styles.protipBanner, variant === 'blue' && styles.blue)}
    >
      <div className="body3 text">{children}</div>
      {!hideIcon && (
        <div aria-hidden className={styles.icon}>
          {icon}
        </div>
      )}
    </div>
  )
}
