import { dayInMs } from '$extensionSrc/utils/dateUtils'

export function getIsoDateString(date: Date): string {
  return date.toISOString().split('T')[0] // i.e., YYYY-MM-DD
}

export function getSqlDateTimeFromIsoString(
  isoString: string | null | undefined,
): string | null {
  if (!isoString) {
    return null
  }
  return isoString.slice(0, 19).replace('T', ' ')
}

export function getDateRange(startDate: Date, endDate: Date): Date[] {
  const dates: Date[] = []
  for (let d = startDate; d <= endDate; d = new Date(d.getTime() + dayInMs)) {
    dates.push(d)
  }
  return dates
}
export function getPriorSunday(date: Date) {
  if (date.getUTCDay() === 0) {
    return new Date(date.getTime() - 7 * dayInMs)
  }
  return new Date(date.getTime() - date.getUTCDay() * dayInMs)
}

export function getDaysSinceSunday(datetime: Date) {
  const date = new Date(datetime)
  date.setUTCHours(0, 0, 0, 0)

  let lastSunday = new Date(date)

  // First, set to start of day
  lastSunday.setUTCHours(0, 0, 0, 0)

  // Next, set to next Sunday
  if (lastSunday.getUTCDay() !== 0) {
    lastSunday = new Date(
      lastSunday.getTime() - lastSunday.getUTCDay() * dayInMs,
    )
  }

  // Finally, return the difference
  return (date.getTime() - lastSunday.getTime()) / dayInMs
}
