import React, { ReactNode } from 'react'

import clsx from 'clsx'

import ActionLogger from '$extensionSrc/components/ActionLogger'

import './InteractiveElement.scss'

/**
 * InteractiveElements include buttons, links, tabs, menu items, and check boxes
 *
 * @param role should specify the behavior of the element if there will be a
 * more specific action than a general click. For instance, role="link" for
 * redirects and role="checkbox" for checkboxes
 */
export default function InteractiveElement({
  children,
  className = '',
  disabled = false,
  onClick = () => {},
  role,
  type = 'button',
  ...properties
}: {
  children: ReactNode
  className?: string
  disabled?: boolean
  onClick?: (event?: any) => void
  role?: string
  type?: 'button' | 'submit' | 'reset'
} & { [key: string]: any }) {
  // Get the text of the button in a way that appropriately reacts to any changes
  const [buttonTextContent, setButtonTextContent] =
    React.useState<ReactNode>(null)
  const buttonTextGetterRef = React.useCallback((node) => {
    if (node) {
      setButtonTextContent(node.textContent)
    }
  }, [])

  const allClassNames = clsx('InteractiveElement', className)
  const label = properties['aria-label'] || properties['aria-labelledby']

  return (
    <ActionLogger
      eventName="element:click"
      buttonTextContent={buttonTextContent?.toString().toLowerCase()}
      getDerivedProps={() => {}}
      label={label}
      role={role}
      actionProps={['onClick']}
    >
      <button
        className={allClassNames}
        disabled={disabled}
        aria-disabled={disabled}
        onClick={onClick}
        ref={buttonTextGetterRef}
        role={role}
        type={type}
        {...properties}
      >
        {children}
      </button>
    </ActionLogger>
  )
}
