import {
  LinkedinRecommendationKey,
  RecommendationTaskType,
} from '../../utils/enums'
import metadata from '../metadata/linkedin.yaml'
import { parseMetadataRegistry } from '../recommendationMetadataParsing'
import { parseSpec } from '../recommendationSpecParsing'
import { Recommendation } from '../recommendationTypes'
import { RecommendationRegistryApi } from '../registryTypes'
import specs from '../specs/linkedin.yaml'

const LINKEDIN_RECOMMENDATION_KEYS = [
  LinkedinRecommendationKey.LINKEDIN_LOCATION_VISIBILITY,
  LinkedinRecommendationKey.LINKEDIN_EMAIL_VISIBILITY,
  LinkedinRecommendationKey.LINKEDIN_CONNECTIONS_LIST,
  LinkedinRecommendationKey.LINKEDIN_REPRESENT_COMPANY,
  LinkedinRecommendationKey.LINKEDIN_PROFILE_DISCOVERABILITY,
  LinkedinRecommendationKey.LINKEDIN_STATUS_VISIBILITY,
  LinkedinRecommendationKey.LINKEDIN_2FA,
  LinkedinRecommendationKey.LINKEDIN_NOTIFICATION_PREFERENCES,
  LinkedinRecommendationKey.LINKEDIN_AI_RESEARCH_OPT_OUT,
]

const LINKEDIN_RECOMMENDATIONS_REGISTRY: Record<
  LinkedinRecommendationKey,
  Recommendation
> = {
  [LinkedinRecommendationKey.LINKEDIN_LOCATION_VISIBILITY]: {
    ...parseSpec(specs[LinkedinRecommendationKey.LINKEDIN_LOCATION_VISIBILITY]),
    key: LinkedinRecommendationKey.LINKEDIN_LOCATION_VISIBILITY,
  },
  [LinkedinRecommendationKey.LINKEDIN_EMAIL_VISIBILITY]: {
    ...parseSpec(specs[LinkedinRecommendationKey.LINKEDIN_EMAIL_VISIBILITY]),
    key: LinkedinRecommendationKey.LINKEDIN_EMAIL_VISIBILITY,
  },
  [LinkedinRecommendationKey.LINKEDIN_CONNECTIONS_LIST]: {
    ...parseSpec(specs[LinkedinRecommendationKey.LINKEDIN_CONNECTIONS_LIST]),
    key: LinkedinRecommendationKey.LINKEDIN_CONNECTIONS_LIST,
  },
  [LinkedinRecommendationKey.LINKEDIN_REPRESENT_COMPANY]: {
    ...parseSpec(specs[LinkedinRecommendationKey.LINKEDIN_REPRESENT_COMPANY]),
    key: LinkedinRecommendationKey.LINKEDIN_REPRESENT_COMPANY,
  },
  [LinkedinRecommendationKey.LINKEDIN_PROFILE_DISCOVERABILITY]: {
    ...parseSpec(
      specs[LinkedinRecommendationKey.LINKEDIN_PROFILE_DISCOVERABILITY],
    ),
    key: LinkedinRecommendationKey.LINKEDIN_PROFILE_DISCOVERABILITY,
  },
  [LinkedinRecommendationKey.LINKEDIN_STATUS_VISIBILITY]: {
    ...parseSpec(specs[LinkedinRecommendationKey.LINKEDIN_STATUS_VISIBILITY]),
    key: LinkedinRecommendationKey.LINKEDIN_STATUS_VISIBILITY,
  },
  [LinkedinRecommendationKey.LINKEDIN_2FA]: {
    ...parseSpec(specs[LinkedinRecommendationKey.LINKEDIN_2FA]),
    hasAutomatedGather: true,
    isAutomated: false,
    key: LinkedinRecommendationKey.LINKEDIN_2FA,
  },
  [LinkedinRecommendationKey.LINKEDIN_NOTIFICATION_PREFERENCES]: {
    ...parseSpec(
      specs[LinkedinRecommendationKey.LINKEDIN_NOTIFICATION_PREFERENCES],
    ),
    key: LinkedinRecommendationKey.LINKEDIN_NOTIFICATION_PREFERENCES,
  },
  [LinkedinRecommendationKey.LINKEDIN_AI_RESEARCH_OPT_OUT]: {
    ...parseSpec(specs[LinkedinRecommendationKey.LINKEDIN_AI_RESEARCH_OPT_OUT]),
    key: LinkedinRecommendationKey.LINKEDIN_AI_RESEARCH_OPT_OUT,
  },
}

export const linkedinRegistry: RecommendationRegistryApi = {
  getRecommendationKeys: () => LINKEDIN_RECOMMENDATION_KEYS,
  getSetupRecommendationKeys: () => LINKEDIN_RECOMMENDATION_KEYS,
  getContentTaskKeys: () =>
    LINKEDIN_RECOMMENDATION_KEYS.filter(
      (key) =>
        LINKEDIN_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.CONTENT,
    ),
  getExperienceTaskKeys: () =>
    LINKEDIN_RECOMMENDATION_KEYS.filter(
      (key) =>
        LINKEDIN_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.EXPERIENCE,
    ),
  getRecommendationsRegistry: () => LINKEDIN_RECOMMENDATIONS_REGISTRY,
  getRecommendationsMetadataRegistry: () => parseMetadataRegistry(metadata),
}
