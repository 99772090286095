import type { PlatformUserAccountData } from './platform/platformUserAccountData'
import { useExtensionData } from '../hooks/extensionStorageListener'
import { PLATFORM, STORAGE_KEY } from '../utils/enums'
import * as localStorage from '../utils/localStorage'

type PlatformAccountData = {
  [platformUserId: string]: PlatformUserAccountData
}

export type AccountData = Partial<Record<PLATFORM, PlatformAccountData>>

export async function getAccountData(): Promise<AccountData> {
  const accountData = await localStorage.getSingle(STORAGE_KEY.ACCOUNT_DATA)

  return accountData || {}
}

export async function setAccountData(accountData: AccountData): Promise<void> {
  return localStorage.set({ [STORAGE_KEY.ACCOUNT_DATA]: accountData })
}

export function useAccountData(): AccountData | undefined {
  const { data, isLoaded } = useExtensionData({
    local: [STORAGE_KEY.ACCOUNT_DATA],
  })

  if (!isLoaded || !data) {
    return undefined
  }

  return data[STORAGE_KEY.ACCOUNT_DATA] || {}
}
