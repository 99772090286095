import { EXTENSION_MESSAGE_TYPES } from './enums'

const _MESSAGE_TYPE_KEY = '__messageType__'

const privacyPartySenderIds = [
  'cjhjphgmgaapneochcffljghaobcljmf', // Chrome DEV
  'gdbcgmjjoolcimdbfmhlimcnbmhlfnog', // Chrome STAGING
  'hefhjoddehdhdgfjhpnffhopoijdfnak', // Chrome PROD
  '{e98a93b8-c453-11ee-a506-0242ac120002}', // Firefox DEV
  '{f53f176a-c453-11ee-a506-0242ac120002}', // Firefox STAGING
  '{3a51fb57-8484-4ae4-8f35-ef83c8f450bc}', // Firefox PROD
  'ilagicciglpheanjmgdomigmfhnidelp', // Edge PROD
]

export function createMessage(messageType, object) {
  return {
    ...object,
    [_MESSAGE_TYPE_KEY]: messageType,
  }
}

export function getMessageType(message) {
  return message[_MESSAGE_TYPE_KEY]
}

export function validateExtensionMessage(message, sender) {
  const messageType = getMessageType(message)

  return (
    Object.values(EXTENSION_MESSAGE_TYPES).includes(messageType) &&
    privacyPartySenderIds.includes(sender.id)
  )
}
