import React, { useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import orderBy from 'lodash/orderBy'

import Item from './Item'
import { getSqlDateTimeFromIsoString } from '../../../../../utils/dateHelpers'

type AttributionType = {
  anonymousUserId: string
  data: Record<string, string>
  lastUpdatedOn: string
}

export default function Attributions({
  anonymousUserIds,
  incomingAttributions,
  label,
}: {
  anonymousUserIds: string[]
  incomingAttributions: string
  label: string
}) {
  const attributions: AttributionType[] = useMemo(() => {
    const parsedIncomingAttributions = JSON.parse(incomingAttributions)
    return orderBy(
      anonymousUserIds.map((anonymousUserId) => ({
        anonymousUserId,
        ...(parsedIncomingAttributions[anonymousUserId] || {}),
      })),
      'lastUpdatedOn',
      'desc',
    )
  }, [anonymousUserIds, incomingAttributions])

  if (Object.values(attributions).length === 0) {
    return null
  }
  return (
    <Item label={label}>
      {attributions.map((a, i) => (
        <Box
          key={a.anonymousUserId}
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.125)',
            marginTop: i > 0 ? '1rem' : '0',
            padding: '0.5rem',
          }}
        >
          <Typography variant="body2">
            {a.anonymousUserId}
            <br />
            {getSqlDateTimeFromIsoString(a.lastUpdatedOn)}
          </Typography>
          {Object.entries(a.data).map(([key, value]) => (
            <Typography variant="body2">
              <strong>{key}</strong>: {value}
            </Typography>
          ))}
          {Object.entries(a.data).length === 0 && (
            <Typography variant="body2">
              <em>No data</em>
            </Typography>
          )}
        </Box>
      ))}
    </Item>
  )
}
