import { PLATFORM, isPlatform } from '../utils/enums'

const disabledPlatforms: PLATFORM[] = []

export function getEnabledPlatforms(): PLATFORM[] {
  return Object.keys(PLATFORM)
    .filter((p) => !disabledPlatforms.includes(PLATFORM[p]))
    .filter(isPlatform)
}

export function isEnabledPlatform(platform: unknown): boolean {
  if (!isPlatform(platform)) {
    return false
  }
  return getEnabledPlatforms().includes(platform)
}
