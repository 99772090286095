import React, { useMemo } from 'react'

import { Box, TableCell, TableRow, Typography } from '@mui/material'

const ALL_URL = new URL('https://app.mezmo.com/3a4058ffb3/logs/view/eb68a51fa2')

type AnonymousUserIdItem = {
  anonymousUserId: string
  url: URL
}

export default function Mezmo({
  anonymousUserIds,
  userAccountId,
}: {
  anonymousUserIds: string[]
  userAccountId?: string
}) {
  const accountUserIdUrl: URL | undefined = useMemo(() => {
    if (!userAccountId) {
      return undefined
    }
    const url = ALL_URL
    url.searchParams.set('q', `user_account_id:${userAccountId}`)
    return url
  }, [userAccountId])

  const anonymousUserIdItems: AnonymousUserIdItem[] = useMemo(
    () =>
      anonymousUserIds.map((anonymousUserId) => {
        const url = ALL_URL
        url.searchParams.set('q', `anonymous_user_id:${anonymousUserId}`)
        return {
          anonymousUserId,
          url,
        }
      }),
    [anonymousUserIds],
  )

  return (
    <TableRow>
      <TableCell sx={{ fontWeight: 'bold', verticalAlign: 'top' }}>
        Mezmo
      </TableCell>
      <TableCell>
        {accountUserIdUrl && (
          <Box
            sx={{ border: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5rem' }}
          >
            <Typography variant="body2">
              View by user account ID:{' '}
              <a
                href={accountUserIdUrl.toString()}
                rel="noreferrer"
                target="_blank"
              >
                {userAccountId}
              </a>
            </Typography>
          </Box>
        )}
        {anonymousUserIds.length > 0 && (
          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.125)',
              marginTop: accountUserIdUrl ? '1rem' : '0',
              padding: '0.5rem',
            }}
          >
            <Typography variant="body2">
              View by anonymous user IDs
              <br />
              <ul>
                {anonymousUserIdItems.map(({ anonymousUserId, url }) => (
                  <li key={anonymousUserId}>
                    <a href={url.toString()} rel="noreferrer" target="_blank">
                      {anonymousUserId}
                    </a>
                  </li>
                ))}
              </ul>
            </Typography>
          </Box>
        )}
      </TableCell>
    </TableRow>
  )
}
