import React, { useMemo } from 'react'

import clsx from 'clsx'

import ConcernTagIcon from '$extensionSrc/components/ConcernTagIcon'
import InteractiveElement from '$extensionSrc/components/InteractiveElement'
import { AccessLevel } from '$extensionSrc/data/user/accessByPlatform'
import CheckPhotosIcon from '$extensionSrc/icons/check-photos-icon.svg'
import LessRelevantIcon from '$extensionSrc/icons/less-relevant.svg'
import SettingsCheckIcon from '$extensionSrc/icons/settings-check-icon.svg'
import {
  ConcernTag,
  RecommendationCardType,
  RecommendationState,
} from '$extensionSrc/utils/enums'

import './RecommendationHeader.scss'

export default function RecommendationHeader({
  accessLevel,
  concernTags,
  hasAutomatedGather,
  isTask,
  isLanguageSupported,
  isLessRelevant,
  isPhoto,
  locked = false,
  onReScanClick,
  recommendationCardType,
  recommendationState,
}: {
  accessLevel: AccessLevel
  concernTags: ConcernTag[]
  hasAutomatedGather: boolean
  isTask: boolean
  isLanguageSupported: boolean
  isLessRelevant: boolean
  isPhoto: boolean
  locked: boolean
  onReScanClick: () => void
  recommendationCardType: string
  recommendationState: RecommendationState
}) {
  const reScanTooltipText = isPhoto
    ? 'Reload these photos'
    : 'Re-scan these settings'
  const lockedError = isLanguageSupported
    ? 'To use this feature, sign up or upgrade your account'
    : 'To use this feature, switch your language settings to US English'

  const showRescanIcon = useMemo(() => {
    if (hasAutomatedGather && (!isTask || accessLevel === AccessLevel.FULL)) {
      return true
    }
    if (recommendationCardType === RecommendationCardType.AUTOMATED) {
      return true
    }
    return false
  }, [accessLevel, hasAutomatedGather, isTask, recommendationCardType])

  return (
    <div
      className={clsx(
        'recommendation-header',
        isLessRelevant ? 'less-relevant' : recommendationState.toLowerCase(),
        isTask && 'task',
      )}
    >
      {concernTags.length > 0 && (
        <div className="concern-tags">
          <div className="concern-tag body3">
            <div className="concern-tag-icon">
              <ConcernTagIcon name={concernTags[0]} />
            </div>
            <div className="concern-tag-name">{concernTags[0]}</div>
          </div>
          {concernTags.length > 1 && (
            <div className="additional">
              <InteractiveElement
                aria-describedby="additional-concern-tags-tooltip"
                aria-label={`View ${concernTags.length - 1} more`}
                className="prompt body3 darker-gray"
              >
                <span className="plus-sign">+</span>
                <div className="concern-tag-icon">
                  <ConcernTagIcon name={concernTags[1]} />
                </div>
              </InteractiveElement>
              <div
                className="tooltip-text body3"
                id="additional-concern-tags-tooltip"
                role="tooltip"
              >
                {concernTags.slice(1, concernTags.length).map((ct) => (
                  <div key={ct}>{ct}</div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="recommendation-header-right d-flex">
        {isLessRelevant && (
          <div className="recommendation-relevance d-flex body3">
            <LessRelevantIcon className="less-relevant-icon" />
            Less relevant
            <span aria-hidden className="tooltip-text body3">
              This recommendation is about concerns you rated as less relevant
              to you
            </span>
          </div>
        )}
        {showRescanIcon && (
          <InteractiveElement
            className="recommendation-header-tab"
            aria-label={locked ? lockedError : reScanTooltipText}
            onClick={onReScanClick}
            disabled={locked}
          >
            <span aria-hidden>
              {isPhoto ? (
                <CheckPhotosIcon className="recommendation-header-tab-rescan-icon" />
              ) : (
                <SettingsCheckIcon className="recommendation-header-tab-rescan-icon" />
              )}
              <span aria-hidden className="tooltip-text body3">
                {locked ? lockedError : reScanTooltipText}
              </span>
            </span>
          </InteractiveElement>
        )}
      </div>
    </div>
  )
}
