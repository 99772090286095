import React, { createContext, useContext, useMemo } from 'react'

export const LogPropsContext = createContext({})

function LogContextProvider({ children, ...directProps }) {
  const consumedProps = useContext(LogPropsContext)

  const combinedProps = useMemo(
    () => ({
      ...consumedProps,
      ...directProps,
    }),
    [consumedProps, directProps],
  )

  return (
    <LogPropsContext.Provider value={combinedProps}>
      {children}
    </LogPropsContext.Provider>
  )
}

export default LogContextProvider
