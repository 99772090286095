import isEmpty from 'lodash/isEmpty'
import setWith from 'lodash/setWith'

import { PLATFORM, isPlatform } from '../../utils/enums'
import { isValidPropertyString } from '../../utils/stringValidator'
import { getAccountData, setAccountData, useAccountData } from '../accountData'
import { isEnabledPlatform } from '../enabledPlatforms'

export type PlatformUserAccountData = {
  /**
   * Used only for Instagram
   */
  accountType?: string

  /**
   * Used only for Instagram
   */
  accountVisibility?: string

  /**
   * Internal; used server-side to identify completed playbooks
   */
  isCompleted?: boolean

  /**
   * Internal; indicates if this data was deleted; helps with sync'ing
   */
  isDeleted?: boolean

  lastUpdatedTimestamp: number

  /**
   * [DEPRECATED] Previously used only for Facebook; waiting to delete
   */
  profileUrl?: string

  /**
   * Username for display purposes, e.g. on the Dashboard
   */
  username?: string
}

type PlatformUserAccountDataSet = Omit<
  PlatformUserAccountData,
  'lastUpdatedTimestamp'
>

type PlatformUserAccountDataUpdate = Omit<
  PlatformUserAccountData,
  'lastUpdatedTimestamp'
>

export async function getPlatformUserAccountData(
  platform: PLATFORM | null | undefined,
  platformUserId: string | undefined,
): Promise<PlatformUserAccountData | null | undefined> {
  if (platform === undefined || platformUserId === undefined) {
    return undefined
  }
  if (platform === null) {
    return null
  }
  const accountData = await getAccountData()
  return accountData[platform]?.[platformUserId] || null
}

export type PlatformUserIdPairs = [PLATFORM, string][]

export async function getPlatformUserIdPairs(): Promise<PlatformUserIdPairs> {
  const accountData = await getAccountData()

  const platformUserIdPairs: PlatformUserIdPairs = []
  if (accountData) {
    Object.keys(accountData).forEach((platform) => {
      Object.keys(accountData[platform]).forEach((platformUserId) => {
        if (isPlatform(platform)) {
          platformUserIdPairs.push([platform, platformUserId])
        }
      })
    })
  }

  return platformUserIdPairs
}

type SetPlatformUserAccountDataOptions = {
  delete?: boolean
}

export async function setPlatformUserAccountData(
  platform: PLATFORM,
  platformUserId: string,
  platformUserAccountDataSet: PlatformUserAccountDataSet,
  options: SetPlatformUserAccountDataOptions = {},
): Promise<void> {
  if (!isEnabledPlatform(platform)) {
    throw new Error(`setPlatformUserAccountData: Invalid platform ${platform}`)
  }
  if (!isValidPropertyString(platformUserId)) {
    throw new Error(
      `setPlatformUserAccountData: Invalid platform user ID ${platformUserId}`,
    )
  }

  let accountData = await getAccountData()

  if (options.delete) {
    accountData = setWith(
      accountData,
      [platform, platformUserId],
      {
        isDeleted: true,
      },
      Object,
    )
  } else {
    const update: PlatformUserAccountDataSet = {
      ...platformUserAccountDataSet,
      isDeleted: false,
    }
    accountData = setWith(
      accountData,
      [platform, platformUserId],
      update,
      Object,
    )
  }

  // Always update timestamp
  accountData = setWith(
    accountData,
    [platform, platformUserId, 'lastUpdatedTimestamp'],
    Date.now(),
    Object,
  )

  return setAccountData(accountData)
}

export async function updatePlatformUserAccountData(
  platform: PLATFORM,
  platformUserId: string,
  platformUserAccountDataUpdate: PlatformUserAccountDataUpdate,
): Promise<void> {
  const platformUserAccountData = await getPlatformUserAccountData(
    platform,
    platformUserId,
  )
  return setPlatformUserAccountData(platform, platformUserId, {
    ...(platformUserAccountData || {}),
    ...platformUserAccountDataUpdate,
  })
}

export async function initPlatformUserAccountData(
  platform: PLATFORM,
  platformUserId: string,
): Promise<void> {
  const platformUserAccountData = await getPlatformUserAccountData(
    platform,
    platformUserId,
  )
  // TODO: This isn't the best check; better would be to define the expected
  // attributes and create a validator for that.
  if (isEmpty(platformUserAccountData)) {
    await setPlatformUserAccountData(platform, platformUserId, {
      isDeleted: false,
      isCompleted: false,
    })
  }
}

export async function setPlatformUserAccountDataIsCompleted(
  platform: PLATFORM,
  platformUserId: string,
  isCompleted: boolean,
) {
  return updatePlatformUserAccountData(platform, platformUserId, {
    isCompleted,
  })
}

export function usePlatformUserAccountData(
  platform: PLATFORM | null | undefined,
  platformUserId: string | undefined,
): PlatformUserAccountData | null | undefined {
  const accountData = useAccountData()
  if (!accountData) {
    return undefined
  }
  if (platform === undefined || platformUserId === undefined) {
    return undefined
  }
  if (platform === null) {
    return null
  }
  return accountData[platform]?.[platformUserId] || null
}
