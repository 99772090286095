import React, { ReactNode } from 'react'

import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { createTheme, ThemeProvider } from '@mui/material'

const defaultTheme = createTheme({
  palette: {
    primary: {
      dark: '#4b18a1',
      light: '#6a4ebf',
      main: '#5b1dc2',
    },
  },
  typography: {
    allVariants: {
      color: '#212529',
    },
    fontFamily: 'Red Hat Text, Helvetica, sans-serif',
  },
})

export default function MaterialUiProvider({
  children,
  cspNonce,
}: {
  children: ReactNode
  cspNonce: string
}) {
  const cache = createCache({
    key: 'emotion',
    nonce: cspNonce,
  })

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
    </CacheProvider>
  )
}
