import React from 'react'

import AccountSecurityIcon from '$extensionSrc/icons/account-security-icon.svg'
import AccountSeparationIcon from '$extensionSrc/icons/account-separation-icon.svg'
import ChildSafetyIcon from '$extensionSrc/icons/child-safety-icon.svg'
import DataMisuseIcon from '$extensionSrc/icons/data-misuse-icon.svg'
import DigitalWellnessIcon from '$extensionSrc/icons/digital-wellness-icon.svg'
import OnlineAbuseIcon from '$extensionSrc/icons/online-abuse-icon.svg'
import PhysicalSafetyIcon from '$extensionSrc/icons/physical-safety-icon.svg'
import * as enums from '$extensionSrc/utils/enums'

export default function ConcernTagIcon({ name }: { name: enums.ConcernTag }) {
  switch (name) {
    case enums.ConcernTag.ACCOUNT_SECURITY:
      return <AccountSecurityIcon />
    case enums.ConcernTag.ACCOUNT_SEPARATION:
      return <AccountSeparationIcon />
    case enums.ConcernTag.CHILD_SAFETY:
      return <ChildSafetyIcon />
    case enums.ConcernTag.DATA_MISUSE:
      return <DataMisuseIcon />
    case enums.ConcernTag.DIGITAL_WELLNESS:
      return <DigitalWellnessIcon />
    case enums.ConcernTag.ONLINE_ABUSE:
      return <OnlineAbuseIcon />
    case enums.ConcernTag.PHYSICAL_SAFETY:
      return <PhysicalSafetyIcon />
    default:
      throw new Error(`Unknown concern tag: ${name}`)
  }
}
