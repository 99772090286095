import React, { useCallback, useState } from 'react'

import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { useRequest } from 'ahooks'

import Tile from './Tile'
import MaterialUiProvider from '../MaterialUiProvider'

export type Priority = 'p0' | 'p1' | 'p2' | 'p3'

export type Timing = {
  created_at: string
  name: string
  url: string
}

export type Stats = {
  assigned: number
  completed: number
  newest: Timing | null
  oldest: Timing | null
  oldest_uncompleted: Timing | null
  resolution: {
    max: string | null
    mean: string | null
    median: string | null
    min: string | null
  }
  unassigned: number
  uncompleted: number
}

type IssueResolutionType = Record<Priority, Stats>

async function fetchIssueResolution(
  days: number | null,
): Promise<IssueResolutionType> {
  let url = '/api/issue-resolution'
  if (days && days > 0) {
    const usp = new URLSearchParams({ days: days.toString() })
    url = `${url}?${usp.toString()}`
  }
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(`Not ok: ${response.status}`)
  }
  const json = await response.json()
  return json
}

export default function IssueResolution({ cspNonce }: { cspNonce: string }) {
  const [days, setDays] = useState<number>(90)

  const handleChange = useCallback((event: SelectChangeEvent) => {
    setDays(parseInt(event.target.value, 10))
  }, [])

  const { data, error, loading } = useRequest<IssueResolutionType, undefined>(
    async () => fetchIssueResolution(days),
    { cacheKey: `days-${days}`, refreshDeps: [days] },
  )

  return (
    <MaterialUiProvider cspNonce={cspNonce}>
      <h1>Issue Resolution</h1>
      <Box sx={{ paddingTop: '16px' }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <FormControl>
                  <InputLabel id="issue-resolution-days-label">Days</InputLabel>
                  <Select
                    label="Days"
                    labelId="issue-resolution-days-label"
                    id="issue-resolution-days"
                    onChange={handleChange}
                    value={days.toString()}
                  >
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={28}>28</MenuItem>
                    <MenuItem value={90}>90</MenuItem>
                    <MenuItem value={180}>180</MenuItem>
                    <MenuItem value={365}>365</MenuItem>
                  </Select>
                  <FormHelperText>
                    Only include tasks that are either uncompleted or that have
                    been completed since this many days ago
                  </FormHelperText>
                </FormControl>
              </Grid>
              {loading && (
                <Grid item px={{ marginTop: '8px' }} xs={1}>
                  <CircularProgress />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {data &&
                Object.entries(data).map(([priority, stats]) => (
                  <Tile
                    key={priority}
                    priority={priority as Priority}
                    stats={stats}
                  />
                ))}
              {!loading && !data && (
                <Grid item>
                  <Typography component="div">No data</Typography>
                </Grid>
              )}
              {error && (
                <Grid item>
                  <Typography component="div">Error loading data</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </MaterialUiProvider>
  )
}
