import { GobyApiCookieType } from '$extensionSrc/utils/enums'

import { apiOrigin, sharedCookieDomain } from '../consts/urlConsts'
import * as cookies from '../utils/cookies'

// Called by getAnonymousUserId in track AND by background-script as a part of
// the Chrome messaging process
export async function getAnonymousUserIdCookie(
  incomingApiOrigin?: string,
): Promise<string | undefined> {
  if (typeof chrome === 'undefined' || !chrome.cookies) {
    return undefined
  }

  let url = incomingApiOrigin
  if (!url) {
    url = apiOrigin
  }

  const anonymousUserIdCookie =
    await cookies.getCookieIgnoreHostPermissionErrors({
      name: GobyApiCookieType.ANONYMOUS_USER_ID,
      url,
    })

  if (!anonymousUserIdCookie) {
    return undefined
  }
  return anonymousUserIdCookie.value
}

// Called by setAnonymousUserId in track AND by background-script as a part of
// the Chrome messaging process
export async function setAnonymousUserIdCookie(
  anonymousUserId: string,
): Promise<void> {
  if (typeof chrome === 'undefined' || !chrome.cookies) {
    return
  }
  await cookies.setCookie({
    name: GobyApiCookieType.ANONYMOUS_USER_ID,
    domain: sharedCookieDomain,
    sameSite: 'lax',
    secure: true,
    url: apiOrigin,
    value: anonymousUserId,
  })
}

export async function removeAnonymousUserIdCookie(): Promise<void> {
  if (typeof chrome === 'undefined' || !chrome.cookies) {
    return
  }
  await cookies.removeCookieIgnoreHostPermissionErrors({
    name: GobyApiCookieType.ANONYMOUS_USER_ID,
    url: apiOrigin,
  })
}
