import { tokenRefreshPath, getApiUrl } from './consts/urlConsts'
import { getJwtCsrfHeader } from './utils/apiAuth'

/**
 * Fetch wrapper that will attach user account tokens if user is logged in
 */
export async function fetchWithAuth(
  url: string,
  requestInit: RequestInit,
  tokenType: 'access' | 'refresh' = 'access',
): Promise<Response> {
  const makeRequest = async () => {
    const request = new Request(url, requestInit)
    const [name, value] = await getJwtCsrfHeader(tokenType)

    if (name && value) {
      request.headers.set(name, value)
    }

    return fetch(request)
  }

  let response = await makeRequest()

  if (tokenType === 'access' && response.status === 401) {
    // We disable this eslint rule because it is convenient
    /* eslint-disable @typescript-eslint/no-use-before-define */
    const refreshResponse = await refreshAccessToken()
    /* eslint-enable @typescript-eslint/no-use-before-define */
    if (refreshResponse !== 401) {
      // do not try the request again if the refresh failed
      response = await makeRequest()
    }
  }

  return response
}

export async function refreshAccessToken(): Promise<number> {
  const response = await fetchWithAuth(
    getApiUrl(tokenRefreshPath),
    { method: 'GET' },
    'refresh',
  )

  return response.status
}
