import React, { ReactNode } from 'react'

import clsx from 'clsx'

import {
  MarkAsDoneButton,
  SkipButton,
  UndoButton,
} from '$extensionSrc/components/RecommendationCardButtons'
import RecommendationCardStepRow from '$extensionSrc/components/RecommendationCardStepRow'
import CheckmarkDoneIcon from '$extensionSrc/icons/checkmark-done-icon.svg'
import { Step } from '$extensionSrc/recommendations/recommendationDisplayWrapper'
import { RecommendationState } from '$extensionSrc/utils/enums'

import './ManualRecommendationCardBody.scss'

export default function ManualRecommendationCardBody({
  customActionDescription,
  defaultBodyTitle,
  recommendationState,
  onClickAction,
  onClickSkip,
  onClickUndo,
  onClickUndoSkip,
  steps = [],
  isAnimatingCardStateTransition,
  locked = false,
}: {
  customActionDescription?: string
  defaultBodyTitle: ReactNode
  isAnimatingCardStateTransition: boolean
  locked: boolean
  onClickAction: () => void
  onClickSkip: () => void
  onClickUndo: () => void
  onClickUndoSkip: () => void
  recommendationState: RecommendationState
  steps: Step[]
}) {
  const stepRowsList = steps.map((step) => (
    <RecommendationCardStepRow
      key={step.id}
      recommendationState={recommendationState}
      label={step.description}
      stepIndex={step.id + 1}
    />
  ))

  const footerDescriptionClassName = clsx(
    'footer-description',
    'body3',
    'darkest-gray',
    recommendationState !== RecommendationState.INCOMPLETE &&
      'space-between no-buttons-underneath',
  )

  let footerDescription
  let footerButtons
  if (recommendationState === RecommendationState.INCOMPLETE) {
    footerDescription = customActionDescription
    footerButtons = (
      <div className="footer-buttons mark-as-done-row">
        <MarkAsDoneButton onClick={onClickAction} disabled={locked} />
        <SkipButton onClick={onClickSkip} disabled={locked} />
      </div>
    )
  } else if (recommendationState === RecommendationState.SKIPPED) {
    footerDescription = (
      <>
        You skipped this recommendation.
        <UndoButton
          onClick={onClickUndoSkip}
          text="Undo"
          includeIcon={false}
          disabled={locked}
        />
      </>
    )
  } else if (recommendationState === RecommendationState.FULFILLED) {
    footerDescription = <>No action required! You're already good to go 🎉</>
  } else {
    footerDescription = (
      <>
        <div className="d-flex">
          <CheckmarkDoneIcon className="checkmark-complete-icon" />
          You've marked this as done
        </div>
        <UndoButton onClick={onClickUndo} text="Undo" includeIcon={false} />
      </>
    )
  }

  return (
    <div
      className={clsx(
        'ManualRecommendationCardBody',
        isAnimatingCardStateTransition && 'fade',
      )}
    >
      {recommendationState !== RecommendationState.INCOMPLETE ? null : (
        <div className="recommendation-body">
          <div className="recommendation-body-title">
            <div className="body1 black bold">{defaultBodyTitle}</div>
          </div>
          {stepRowsList}
        </div>
      )}
      <div className="recommendation-footer">
        <div className={footerDescriptionClassName}>{footerDescription}</div>
        {footerButtons}
      </div>
    </div>
  )
}
