import React from 'react'

import RecommendationCard from '$extensionSrc/components/RecommendationCard'
import RecommendationDisplayWrapper from '$extensionSrc/recommendations/recommendationDisplayWrapper'
import { parseSpec } from '$extensionSrc/recommendations/recommendationSpecParsing'
import {
  Recommendation,
  RecommendationKeyEnum,
} from '$extensionSrc/recommendations/recommendationTypes'
import { ConcernTag, RecommendationState } from '$extensionSrc/utils/enums'

import './RecommendationCardPreview.scss'

export default function RecommendationCardPreview({
  error,
  concernTag,
  specs: specsStr,
}: {
  error?: string
  concernTag?: ConcernTag
  specs?: string
}) {
  let content
  if (error) {
    content = `Error parsing YAML: ${error}`
  } else {
    let specs: Record<string, Recommendation> = {}
    if (specsStr) {
      try {
        specs = JSON.parse(specsStr)
      } catch {
        specs = {}
      }
    } else {
      specs = {}
    }
    content = Object.entries(specs).map(([recommendationKey, spec]) => {
      let parsedSpec = {} as Recommendation

      let settingsDef
      if (spec.isAutomated) {
        settingsDef = {
          getCantUndoFn: () => () => false,
          getCurrentValueFn: () => () => 'value',
          getIsDisabledFn: () => () => false,
          getIsDisabledReasonFn: () => () => null,
          getIsFulfilledFn: () => () => false,
          gatherStageDefs: [],
          updateStageDefs: [],
          undoStageDefs: [],
        }
      }

      parsedSpec = {
        ...parseSpec(spec),
        key: recommendationKey as RecommendationKeyEnum,
      }

      const progress = {
        initialState: {},
        automationErrors: null,
        recommendationState: RecommendationState.INCOMPLETE,
        lastGatheredTimestamp: null,
        lastUpdatedTimestamp: -1,
        settingsSelected: parsedSpec.settings
          ? Object.fromEntries(
              parsedSpec.settings.map((setting) => [setting.key, true]),
            )
          : {},
      }

      return (
        <div className="recommendation-card-container" key={recommendationKey}>
          <RecommendationCard
            accessLevel={undefined}
            automationDef={settingsDef}
            displayPosition={0}
            isLanguageSupported
            isLessRelevant={false}
            isPlaybookCompleted={false}
            lastDeeplinkRecommendationKey={null}
            platform="test-platform"
            progress={progress}
            recommendationKey={recommendationKey}
            onAnimatedTransition={() => {}}
            onAutomationStarted={() => {}}
            showUpsellModal={() => {}}
            spec={
              new RecommendationDisplayWrapper(
                parsedSpec,
                concernTag || parsedSpec.concernTags[0],
              )
            }
            userId="test-user-id"
          />
        </div>
      )
    })
  }

  return <div className="RecommendationCardPreview">{content}</div>
}
