import React, { ReactNode } from 'react'

import { RecommendationState } from '$extensionSrc/utils/enums'

import './RecommendationCardStepRow.scss'

export default function RecommendationCardStepRow({
  recommendationState,
  label,
  stepIndex,
}: {
  recommendationState: RecommendationState
  label: ReactNode
  stepIndex: number
}) {
  let icon
  let labelClassName
  if (
    recommendationState === RecommendationState.FULFILLED ||
    recommendationState === RecommendationState.UPDATED
  ) {
    labelClassName = 'todo-or-done'
    icon = (
      <span className="step-icon completed body2 darkest-gray">
        {stepIndex}.
      </span>
    )
  } else if (recommendationState === RecommendationState.SKIPPED) {
    labelClassName = 'skipped'
    icon = <span className="step-icon body2 darkest-gray">{stepIndex}.</span>
  } else {
    labelClassName = 'body2 todo-or-done'
    icon = <span className="step-icon body2 black">{stepIndex}.</span>
  }

  return (
    <div className="RecommendationCardStepRow d-flex flex-row">
      {icon}
      <div className="d-flex flex-column">
        <div className={`body2 step-label ${labelClassName}`}>{label}</div>
      </div>
    </div>
  )
}
