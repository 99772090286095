function isNotEmpty(str: string): boolean {
  return Boolean(str)
}

function isValidEmail(str: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(str)
}

function hasMinLength(str: string, numCharacters: number): boolean {
  return isNotEmpty(str) && numCharacters > 0 && str.length >= numCharacters
}

const defaultObjectPrototypeProperties = [
  '__defineGetter__',
  '__defineSetter__',
  '__lookupGetter__',
  '__lookupSetter__',
  '__proto__',
  'constructor',
  'hasOwnProperty',
  'isPrototypeOf',
  'propertyIsEnumerable',
  'toLocaleString',
  'toString',
  'valueOf',
]

function isValidPropertyString(prop: string) {
  return !defaultObjectPrototypeProperties.includes(prop)
}

export { isNotEmpty, hasMinLength, isValidEmail, isValidPropertyString }
