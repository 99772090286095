const BuildConfig = {
  APP_NAME: process.env.APP_NAME,
  BROWSER: process.env.BROWSER,
  EXTENSION_API_ORIGIN: process.env.EXTENSION_API_ORIGIN,
  MARKETING_SITE_ORIGIN: process.env.MARKETING_SITE_ORIGIN,
  /**
   * Origin for cookies intended to be accessible by both the
   * extension server and the marketing site; since the
   * extension server is currently a subdomain of the marketing
   * site, we use the marketing site origin.
   */
  SHARED_COOKIE_DOMAIN: process.env.SHARED_COOKIE_DOMAIN,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  STAGING_BASIC_AUTH: process.env.STAGING_BASIC_AUTH,
}

export default BuildConfig
