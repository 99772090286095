import React, { useState, useRef, PropsWithChildren } from 'react'

import clsx from 'clsx'
import isString from 'lodash/isString'

import './MetricsDataUpload.scss'

function StylableFileInput({
  onChange,
  className,
  children,
}: PropsWithChildren<{ onChange: (e: any) => void; className: string }>) {
  const inputRef = useRef<HTMLInputElement>(null)

  const onClickButton = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <button
      className={clsx('StylableFileInput', className)}
      onClick={onClickButton}
    >
      <input
        ref={inputRef}
        className="hidden-input"
        type="file"
        onChange={onChange}
      />
      {children}
    </button>
  )
}

function parseCsv(
  content: string,
  options: { nSkipRows?: number; commentIndicator?: string },
): { [key: string]: string }[] {
  // Parse content into lines
  let lines = content
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => line.length > 0)

  // Handle parsing options
  if (options.nSkipRows) {
    lines = lines.slice(options.nSkipRows)
  }

  if (options.commentIndicator) {
    lines = lines.filter(
      (line) =>
        options.commentIndicator && !line.startsWith(options.commentIndicator),
    )
  }

  const headers = lines[0].split(',')
  const data = lines.slice(1).map((line) => {
    const values = line.split(',')
    return headers.reduce((acc, header, idx) => {
      acc[header] = values[idx]
      return acc
    }, {})
  })
  return data
}

const dayInMs = 24 * 60 * 60 * 1000
function fillMissingDates(
  data: { date: string; value: number }[],
  fillValue: number,
): { date: string; value: number }[] {
  // Assumed that all dates are in isoformat
  const foundDates = data.map((row) => row.date)
  foundDates.sort((a, b) => a.localeCompare(b))

  const earliestDate = new Date(foundDates[0])
  const latestDate = new Date(foundDates[foundDates.length - 1])

  const foundDatesAsSet = new Set(foundDates)
  const missingDates: string[] = []
  let currentDate = new Date(earliestDate)
  while (currentDate <= latestDate) {
    const currentDateIso = currentDate.toISOString().split('T')[0]
    if (!foundDatesAsSet.has(currentDateIso)) {
      missingDates.push(currentDateIso)
    }
    currentDate = new Date(currentDate.getTime() + dayInMs)
  }

  const missingData = missingDates.map((date) => ({ date, value: fillValue }))

  return [...data, ...missingData]
}

type ValidDataSource =
  | 'chrome_webstore_console'
  | 'firefox_addon_developer_hub'
  | 'edge_partner_center'

function getDataSourceGuessGivenFilename(
  filename: string,
): ValidDataSource | null {
  if (filename.startsWith('Installs_hefhjoddehdhdgfjhpnffhopoijdfnak')) {
    return 'chrome_webstore_console'
  } else if (filename.startsWith('downloads-day-')) {
    return 'firefox_addon_developer_hub'
  } else if (filename.startsWith('edgeaddon_analytics')) {
    return 'edge_partner_center'
  }
  return null
}

function getSeriesNameGivenDataSource(
  source: ValidDataSource | null,
): string | null {
  switch (source) {
    case 'chrome_webstore_console':
      return 'installs_chrome'
    case 'firefox_addon_developer_hub':
      return 'installs_firefox'
    case 'edge_partner_center':
      return 'installs_edge'
    default:
      return null
  }
}

function parseCsvByDataSource(
  content: string,
  dataSource: ValidDataSource,
): { date: string; value: number }[] | null {
  let parsedData
  switch (dataSource) {
    case 'chrome_webstore_console':
      parsedData = parseCsv(content, { nSkipRows: 1 }).map((row) => ({
        date: new Date(row.Date).toISOString().split('T')[0],
        value: parseInt(row.Installs, 10),
      }))
      break
    case 'firefox_addon_developer_hub':
      // Firefox has some comment lines; columns are ("date", "count")
      // Dates are yyyy-mm-dd
      parsedData = parseCsv(content, { commentIndicator: '#' }).map((row) => ({
        date: row.date,
        value: parseInt(row.count, 10),
      }))
      break
    case 'edge_partner_center':
      parsedData = parseCsv(content, {}).map((row) => ({
        date: row.date,
        value: parseInt(row.userCount, 10),
      }))
      break
    default:
    // pass
  }

  if (parsedData) {
    parsedData = fillMissingDates(parsedData, 0)
    parsedData.sort((a, b) => b.date.localeCompare(a.date))
  }

  return parsedData
}

function TwoColumnDateValueTable({
  data,
}: {
  data: { date: string; value: number }[]
}) {
  return (
    <table className="TwoColumnDateValueTable">
      <thead>
        <tr>
          <th>Date</th>
          <th className="header-value">Value</th>
        </tr>
      </thead>
      <tbody>
        {data ? (
          data.map((row, i) => (
            <tr
              className={clsx(i % 2 === 0 ? 'even-row' : 'odd-row')}
              key={row.date}
            >
              <td>{row.date}</td>
              <td className="row-value">{row.value}</td>
            </tr>
          ))
        ) : (
          <tr className="odd-row">
            <td>YYYY-MM-DD</td>
            <td className="row-value">count</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default function MetricsDataUpload() {
  const [filename, setFilename] = useState<string | null>(null)
  const [fileContent, setFileContent] = useState<string | ArrayBuffer | null>(
    null,
  )
  const [dataSource, setDataSource] = useState<ValidDataSource | null>(null)
  const [statusMessage, setStatusMessage] = useState<string | null>(null)
  const [buttonTemporarilyDisabled, setButtonTemporarilyDisabled] =
    useState(false)

  let parsedContent: { date: string; value: number }[] | null = null
  if (isString(fileContent) && dataSource) {
    try {
      parsedContent = parseCsvByDataSource(fileContent, dataSource)
    } catch {
      setStatusMessage('Failed to parse file')
    }
  }

  function handleFile(file: File) {
    setFilename(file.name)

    const foundDataSource = getDataSourceGuessGivenFilename(file.name)
    setDataSource(foundDataSource)

    const reader = new FileReader()
    reader.onload = (evt) => {
      setFileContent(evt.target?.result || null)
    }
    reader.readAsText(file, 'UTF-8')
  }

  function onChangeFileInput(e) {
    handleFile(e.target.files[0])
    e.target.value = ''
  }

  function onDropInDropZone(e) {
    e.stopPropagation()
    e.preventDefault()

    const dt = e.dataTransfer
    const files = dt.files

    if (files.length > 1) {
      setStatusMessage('Only one file at a time')
    }

    handleFile(files[0])
  }

  function clearAll() {
    setFilename(null)
    setFileContent(null)
    setDataSource(null)
    setStatusMessage(null)
  }

  function onClickSubmit() {
    setButtonTemporarilyDisabled(true)

    if (parsedContent) {
      fetch('/api/upload-timeseries-data/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-csrf-token': window.csrfToken || '',
        },
        body: JSON.stringify({
          series: parsedContent,
          seriesName: getSeriesNameGivenDataSource(dataSource),
          dataSource,
        }),
      })
        .then(async (response) => {
          if (response.ok) {
            setStatusMessage('Success! Resetting...')
            setTimeout(() => {
              clearAll()
            }, 2000)
          } else {
            const text = await response.text()
            throw new Error(`${response.status} - ${text}`)
          }
        })
        .catch((error) => {
          setStatusMessage(`Failed to upload: ${error.message}`)
        })
        .finally(() => {
          setButtonTemporarilyDisabled(false)
        })
    }
  }

  function cancelEvent(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <div className="MetricsDataUpload">
      <div className="upload-tool-wrapper">
        <div className="upload-tool-side">
          <span>Upload Tool</span>
          <div
            className="drop-zone"
            onDragEnter={cancelEvent}
            onDragOver={cancelEvent}
            onDrop={onDropInDropZone}
          >
            <StylableFileInput
              className="clickable"
              onChange={(e) => onChangeFileInput(e)}
            >
              {!filename ? 'Drop or click here' : `File: ${filename}`}
            </StylableFileInput>
          </div>
          <div>Data source: {dataSource}</div>
          <div>
            Series name:{' '}
            {dataSource ? getSeriesNameGivenDataSource(dataSource) : null}
          </div>
          <button
            onClick={onClickSubmit}
            disabled={!parsedContent || buttonTemporarilyDisabled}
          >
            Submit
          </button>
          <div>{statusMessage}</div>
        </div>
        <div className="display-side">
          <span>Data preview</span>
          {parsedContent && <TwoColumnDateValueTable data={parsedContent} />}
        </div>
      </div>
    </div>
  )
}
