import { RecommendationMetadataSpec } from './recommendationTypes'

export function parseMetadata(rawRecommendationMetadataFromYaml: {
  [key: string]: string
}): RecommendationMetadataSpec {
  const parsedMetadata = {
    createdOn: Date.parse(rawRecommendationMetadataFromYaml.createdOn),
    estimatedTimeMs: Number(rawRecommendationMetadataFromYaml.estimatedTimeMs),
    importanceScore: Number(rawRecommendationMetadataFromYaml.importanceScore),
  }

  return parsedMetadata
}

export function parseMetadataRegistry(rawRecommendationsMetadataFromYaml: {
  [key: string]: { [key: string]: string }
}): { [key: string]: RecommendationMetadataSpec } {
  const registry = {}

  for (const [
    recommendationKey,
    rawRecommendationMetadataFromYaml,
  ] of Object.entries(rawRecommendationsMetadataFromYaml)) {
    registry[recommendationKey] = parseMetadata(
      rawRecommendationMetadataFromYaml,
    )
  }

  return registry
}
