import React, { useState, useEffect, ReactNode, useMemo } from 'react'

import clsx from 'clsx'

import { RecommendationAutomationDef } from '$extensionSrc/automations/interfaces'
import InteractiveElement from '$extensionSrc/components/InteractiveElement'
import {
  CheckPhotosButton,
  CheckSettingsButton,
  MarkAsDoneButton,
  SkipButton,
  UndoButton,
  UpdateForMeButton,
  WarningButton,
  WarningCancelButton,
} from '$extensionSrc/components/RecommendationCardButtons'
import RecommendationCardSettingRow from '$extensionSrc/components/RecommendationCardSettingRow'
import { usePlatformName } from '$extensionSrc/data/platform/name'
import { AccessLevel } from '$extensionSrc/data/user/accessByPlatform'
import CaretIcon from '$extensionSrc/icons/caret-icon.svg'
import ExpandSectionIcon from '$extensionSrc/icons/expand-icon-closed.svg'
import CloseSectionIcon from '$extensionSrc/icons/expand-icon-open.svg'
import GrayBars from '$extensionSrc/icons/gray-bars.svg'
import WarningIcon from '$extensionSrc/icons/warning-icon.svg'
import * as state from '$extensionSrc/state'
import {
  getTimeSinceTimestampStr,
  isMoreThanOneHourAgo,
} from '$extensionSrc/utils/dateUtils'
import { PLATFORM, RecommendationState } from '$extensionSrc/utils/enums'

import './AutomatedRecommendationCardBody.scss'

export default function AutomatedRecommendationCardBody({
  accessLevel,
  animateFirstRenderForUpdatedCard,
  automationDef,
  automationErrors,
  canUndo,
  customActionDescription,
  defaultBodyTitle,
  initialState,
  isLanguageSupported,
  isLessRelevant,
  isPhoto,
  lastGatheredTimestamp,
  lastUpdatedTimestamp,
  onClickAction,
  onClickMarkAsDone,
  onClickScanSettings,
  onClickSkip,
  onClickUndo,
  onClickUndoMarkAsDone,
  onClickUndoSkip,
  onClickUpsell,
  platform,
  recommendationKey,
  recommendationState,
  settings,
  settingsSelected,
  userId,
  isAnimatingCardStateTransition,
  locked = false,
  truncate = false,
  showGlow = false,
}: {
  accessLevel: AccessLevel
  animateFirstRenderForUpdatedCard: boolean
  automationDef: RecommendationAutomationDef
  automationErrors: { [key: string]: any } | null
  canUndo: boolean
  customActionDescription?: string
  defaultBodyTitle: React.ReactNode
  initialState: { [key: string]: any } | null
  isLanguageSupported: boolean
  isLessRelevant: boolean
  isPhoto: boolean
  lastGatheredTimestamp: number | null
  lastUpdatedTimestamp: number | null
  onClickAction: () => void
  onClickMarkAsDone: () => void
  onClickScanSettings: () => void
  onClickSkip: () => void
  onClickUndo: () => void
  onClickUndoMarkAsDone: () => void
  onClickUndoSkip: () => void
  onClickUpsell: () => void
  platform: string
  recommendationKey: string
  recommendationState: RecommendationState
  settings: { key: string; description: ReactNode }[]
  settingsSelected: { [key: string]: boolean }
  userId: any
  isAnimatingCardStateTransition: boolean
  locked: boolean
  truncate: boolean
  showGlow: boolean
}) {
  const [showWarning, setShowWarning] = useState(false)
  const [reloading, setReloading] = useState(false)
  const [showFulfilledSettings, setShowFulfilledSettings] = useState(
    recommendationState === RecommendationState.FULFILLED,
  )

  const [shouldTruncate, setShouldTruncate] = useState(truncate)

  const platformName = usePlatformName(platform as PLATFORM)

  useEffect(() => {
    if (lastGatheredTimestamp && Date.now() - lastGatheredTimestamp < 1000) {
      setReloading(true)
      setTimeout(() => {
        setReloading(false)
      }, 500)
    }
  }, [lastGatheredTimestamp])

  const timestampStr: string | undefined = useMemo(() => {
    if (
      recommendationState === RecommendationState.UPDATED &&
      lastUpdatedTimestamp &&
      lastUpdatedTimestamp > -1
    ) {
      // If this recommendation is in an updated state, the recommendation card
      // footer should show the updated time instead of the gathered time
      return `${getTimeSinceTimestampStr(lastUpdatedTimestamp)} ago`
    } else if (lastGatheredTimestamp && lastGatheredTimestamp > -1) {
      return `${getTimeSinceTimestampStr(lastGatheredTimestamp)} ago`
    }
    return undefined
  }, [lastGatheredTimestamp, lastUpdatedTimestamp, recommendationState])

  const toggleSettingState = (settingKey) => {
    const updatedSettings = {
      ...settingsSelected,
      [settingKey]: !settingsSelected[settingKey],
    }

    state.updateSettingsSelected(
      platform,
      userId,
      recommendationKey,
      updatedSettings,
    )
    return updatedSettings
  }

  const settingsFulfilledList: ReactNode[] = []
  const settingsUnfulfilledList: ReactNode[] = []
  let nSettingsSelected = 0
  let nSettingsSelectedUndoable = 0

  settings.forEach((setting) => {
    // Default disabled in access level full; else default *not* disabled
    const isSettingDisabled = initialState
      ? automationDef.getIsDisabledFn(setting.key)?.(automationErrors)
      : accessLevel === AccessLevel.FULL
    const isSettingDisabledReason = initialState
      ? automationDef.getIsDisabledReasonFn(setting.key)?.(automationErrors)
      : null
    const isSettingFulfilled = initialState
      ? automationDef.getIsFulfilledFn(setting.key)?.(initialState)
      : false
    const cantUndoSetting = initialState
      ? automationDef.getCantUndoFn(setting.key)?.(initialState)
      : false
    const currentValue = initialState
      ? automationDef.getCurrentValueFn(setting.key)?.(initialState)
      : null

    // Setting selected default to true if no value in progress
    const isSettingSelected =
      settingsSelected[setting.key] !== null &&
      settingsSelected[setting.key] !== undefined
        ? settingsSelected[setting.key]
        : true

    if (!isSettingFulfilled && isSettingSelected) {
      nSettingsSelected += 1
      if (cantUndoSetting) {
        nSettingsSelectedUndoable += 1
      }
    }

    let settingRow: ReactNode
    if (
      recommendationState === RecommendationState.UPDATED &&
      accessLevel === AccessLevel.PREVIEW
    ) {
      /**
       * If in preview mode, we want the checkboxes /not/ to have the gear
       * (as they were manually updated, not automatically)
       *
       * Accomplished by defining `isSettingFulfilled` as true in the
       * event the setting is either selected or fulfilled
       */
      settingRow = (
        <RecommendationCardSettingRow
          currentValueDescription={currentValue}
          key={setting.key}
          isLessRelevant={isLessRelevant}
          isSettingDisabled={isSettingDisabled}
          isSettingDisabledReason={isSettingDisabledReason}
          isSettingFulfilled={isSettingSelected || isSettingFulfilled}
          isSettingNotUndoable={false}
          isSettingSelected={
            isSettingSelected && !isSettingFulfilled && !isSettingDisabled
          }
          label={setting.description}
          labelId={`${recommendationKey}-${setting.key}-label`}
          recommendationState={recommendationState}
          onSelectSetting={() => {}}
          locked={false}
        />
      )
    } else {
      settingRow = (
        <RecommendationCardSettingRow
          currentValueDescription={currentValue}
          key={setting.key}
          isLessRelevant={isLessRelevant}
          isSettingDisabled={isSettingDisabled}
          isSettingDisabledReason={isSettingDisabledReason}
          isSettingFulfilled={
            isSettingFulfilled ||
            // Don't show gear when card was marked as done in PREVIEW
            // and user is now in FULL
            (recommendationState === RecommendationState.UPDATED &&
              accessLevel === AccessLevel.FULL &&
              !initialState &&
              isSettingSelected)
          }
          isSettingNotUndoable={cantUndoSetting}
          isSettingSelected={
            isSettingSelected && !isSettingFulfilled && !isSettingDisabled
          }
          label={setting.description}
          labelId={`${recommendationKey}-${setting.key}-label`}
          recommendationState={recommendationState}
          onSelectSetting={
            locked
              ? () => {}
              : () => {
                  setShouldTruncate(false)
                  toggleSettingState(setting.key)
                }
          }
          locked={locked}
        />
      )
    }

    if (isSettingFulfilled) {
      settingsFulfilledList.push(settingRow)
    } else {
      settingsUnfulfilledList.push(settingRow)
    }
  })

  const lockedMessage = isLanguageSupported
    ? 'To use this feature, sign up or upgrade your account'
    : `To use this feature, switch your language settings on
      ${platformName} to US English`

  const cardIsNotUndoable =
    !canUndo ||
    (nSettingsSelected > 0 && nSettingsSelected === nSettingsSelectedUndoable)

  const nSettingsUpdated =
    recommendationState === RecommendationState.UPDATED
      ? nSettingsSelected
      : null

  const footerDescriptionClassNames = [
    'footer-description',
    'body3',
    'darkest-gray',
  ]
  let footerDescription
  let footerButtons

  if (locked) {
    footerDescription = cardIsNotUndoable ? (
      <>
        <WarningIcon
          className="warning-icon"
          aria-label="Warning: can't undo this update"
        />
        <div>
          {customActionDescription ||
            "After updating, Block Party can't undo this update."}
        </div>
      </>
    ) : (
      customActionDescription || <>You can undo this settings update later.</>
    )
    footerButtons = cardIsNotUndoable ? (
      <div className={clsx('footer-buttons', showGlow && 'glow')}>
        <UpdateForMeButton onClick={() => setShowWarning(true)} disabled />
        <SkipButton onClick={onClickSkip} disabled />
      </div>
    ) : (
      <div className={clsx('footer-buttons', showGlow && 'glow')}>
        <UpdateForMeButton onClick={onClickAction} disabled />
        <SkipButton disabled onClick={onClickSkip} />
      </div>
    )
  } else if (
    recommendationState === RecommendationState.INCOMPLETE &&
    accessLevel === AccessLevel.PREVIEW
  ) {
    // Footer design for BP 2.0 "Preview" sidebar
    footerDescription = null
    footerButtons = (
      <div className={clsx('footer-buttons', showGlow && 'glow')}>
        <UpdateForMeButton preview onClick={onClickUpsell} />
        <div className="mark-as-done-row">
          <MarkAsDoneButton onClick={onClickMarkAsDone} />
          <SkipButton onClick={onClickSkip} />
        </div>
      </div>
    )
  } else if (
    recommendationState === RecommendationState.INCOMPLETE &&
    accessLevel === AccessLevel.FULL
  ) {
    if (initialState) {
      footerDescription = customActionDescription || (
        <>You can undo this settings update later.</>
      )
      footerButtons = (
        <div className={clsx('footer-buttons', showGlow && 'glow')}>
          <UpdateForMeButton
            onClick={onClickAction}
            disabled={nSettingsSelected === 0}
          />
          <SkipButton onClick={onClickSkip} />
        </div>
      )
    } else {
      footerButtons = isPhoto ? (
        <CheckPhotosButton onClick={onClickScanSettings} disabled={locked} />
      ) : (
        <CheckSettingsButton onClick={onClickScanSettings} disabled={locked} />
      )
    }

    if (cardIsNotUndoable) {
      if (showWarning) {
        footerDescription = (
          <span>
            <strong>Block Party cannot undo this later.</strong> Do you want to
            continue?
          </span>
        )
        footerButtons = (
          <>
            <WarningButton onClick={onClickAction} />
            <WarningCancelButton onClick={() => setShowWarning(false)} />
          </>
        )
      } else {
        const warning =
          customActionDescription ||
          "After updating, Block Party can't undo this update."
        footerDescription = (
          <>
            <WarningIcon
              className="warning-icon"
              aria-label="Warning: can't undo this update"
            />
            <div>{warning}</div>
          </>
        )

        footerButtons = (
          <div className={clsx('footer-buttons', showGlow && 'glow')}>
            <UpdateForMeButton
              onClick={() => setShowWarning(true)}
              disabled={nSettingsSelected === 0}
            />
            <SkipButton onClick={onClickSkip} />
          </div>
        )
      }
    } else if (nSettingsSelectedUndoable > 0) {
      footerDescription = (
        <>
          <WarningIcon
            className="warning-icon"
            aria-label="Warning: can't undo some changes"
          />
          After updating, Block Party can't undo some changes.
        </>
      )
    }
  } else if (recommendationState === RecommendationState.FULFILLED) {
    footerDescriptionClassNames.push(
      timestampStr ? 'space-between' : '',
      'no-buttons-underneath',
    )
    footerDescription = (
      <>
        No action required! You're already good to go 🎉
        <div className="timestamp">{timestampStr}</div>
      </>
    )
  } else if (
    recommendationState === RecommendationState.UPDATED &&
    accessLevel === AccessLevel.PREVIEW
  ) {
    footerDescriptionClassNames.push('space-between', 'no-buttons-underneath')
    footerDescription = (
      <>
        You updated {nSettingsUpdated} setting{nSettingsUpdated !== 1 && 's'} 🎉
        <UndoButton
          onClick={onClickUndoMarkAsDone}
          text="Undo"
          includeIcon={false}
        />
      </>
    )
  } else if (
    recommendationState === RecommendationState.UPDATED &&
    accessLevel === AccessLevel.FULL &&
    nSettingsUpdated
  ) {
    footerDescriptionClassNames.push(
      timestampStr || !cardIsNotUndoable ? 'space-between' : '',
      'no-buttons-underneath',
    )
    if (
      cardIsNotUndoable ||
      (lastUpdatedTimestamp && isMoreThanOneHourAgo(lastUpdatedTimestamp))
    ) {
      footerDescription = (
        <>
          You updated {nSettingsUpdated} setting{nSettingsUpdated > 1 && 's'} 🎉
          <div className="timestamp">{timestampStr}</div>
        </>
      )
    } else if (initialState) {
      footerDescription = (
        <>
          You updated {nSettingsUpdated} setting{nSettingsUpdated > 1 && 's'} 🎉
          <UndoButton
            onClick={onClickUndo}
            text="Undo"
            includeIcon={false}
            disabled={false}
          />
        </>
      )
    } else {
      footerDescription = (
        <>
          You updated {nSettingsUpdated} setting{nSettingsUpdated > 1 && 's'}{' '}
          (manually) 🎉
        </>
      )
    }
  } else if (recommendationState === RecommendationState.SKIPPED) {
    footerDescriptionClassNames.push('space-between', 'no-buttons-underneath')
    footerDescription = (
      <>
        You skipped this recommendation.
        <UndoButton
          onClick={onClickUndoSkip}
          text="Undo"
          includeIcon={false}
          disabled={false}
        />
      </>
    )
  }

  const toggleShowFulfilled = () => {
    setShowFulfilledSettings((prevValue) => !prevValue)
  }

  const truncateLongCard = shouldTruncate && settingsUnfulfilledList.length > 2

  let content = (
    <>
      <div
        className={clsx(
          'recommendation-body',
          recommendationState !== RecommendationState.INCOMPLETE && 'no-title',
          truncateLongCard && 'truncated',
        )}
      >
        {recommendationState === RecommendationState.INCOMPLETE && (
          <div className="recommendation-body-title">
            <div className="body1 black bold">{defaultBodyTitle}</div>
          </div>
        )}
        {truncateLongCard
          ? settingsUnfulfilledList.slice(0, 2)
          : settingsUnfulfilledList}
        {settingsFulfilledList.length > 0 && (
          <div className="recommendation-body-fulfilled">
            <div className="recommendation-body-fulfilled-toggle body3">
              {`${settingsFulfilledList.length} setting${
                settingsFulfilledList.length > 1 ? 's' : ''
              } already safe`}
              {!showFulfilledSettings ? (
                <InteractiveElement
                  aria-label="Show fulfilled settings"
                  aria-expanded={showFulfilledSettings}
                  onClick={toggleShowFulfilled}
                >
                  <ExpandSectionIcon
                    aria-hidden
                    className="expand-fulfilled-icon"
                  />
                </InteractiveElement>
              ) : (
                <InteractiveElement
                  aria-label="Show fulfilled settings"
                  aria-expanded={showFulfilledSettings}
                  onClick={toggleShowFulfilled}
                >
                  <CloseSectionIcon
                    aria-hidden
                    className="expand-fulfilled-icon"
                  />
                </InteractiveElement>
              )}
            </div>
            {showFulfilledSettings && (
              <div className="recommendation-body-fulfilled-settings">
                {settingsFulfilledList}
              </div>
            )}
          </div>
        )}
      </div>
      {truncateLongCard && (
        <InteractiveElement
          className="untruncate-button body3"
          role="button"
          onClick={() => setShouldTruncate(false)}
        >
          See all {settingsUnfulfilledList.length} settings{' '}
          <CaretIcon className="caret-icon" />
        </InteractiveElement>
      )}
      <div
        className={clsx(
          'recommendation-footer',
          truncateLongCard && 'truncated',
        )}
      >
        {footerDescription && !truncateLongCard && (
          <div className={clsx(footerDescriptionClassNames)}>
            {footerDescription}
          </div>
        )}
        {footerButtons}
        {locked && (
          <span aria-hidden className="tooltip-text body3">
            {lockedMessage}
          </span>
        )}
      </div>
    </>
  )
  if (reloading) {
    content = (
      <div className="recommendation-body">
        <div className="check-settings-illustration">
          <GrayBars aria-hidden className="gray-bars" />
        </div>
      </div>
    )
  }

  return (
    <div
      className={clsx(
        'AutomatedRecommendationCardBody',
        isAnimatingCardStateTransition ||
          (animateFirstRenderForUpdatedCard && 'fade'),
      )}
    >
      {content}
    </div>
  )
}
