import { STORAGE_KEY } from '../utils/enums'
import * as localStorage from '../utils/localStorage'

/**
 * We use navigator.doNotTrack to determine whether the user has set up DNT
 * on their browser.
 *
 * There is kind of a standard, but it is not implemented consistently and
 * also isn't an official standard. DNT might disappear out of use eventually.
 *
 * Details:
 * - the standard is to use values of "1" for yes DNT and else "0" or null
 * - Firefox uses "unspecified" instead of null
 * - Safari has abandoned this; navigator.doNotTrack will be undefined
 */
export function getBrowserDoNotTrackSetting() {
  // We can only access navigator.doNotTrack from a Navigator, not a
  // WorkerNavigator
  if (typeof window === 'undefined') {
    return undefined
  }
  const dnt = navigator.doNotTrack
  if (
    // Say DNT is enabled if we find an affirmative value
    dnt === '1'
  ) {
    return true
  } else if (
    // Say DNT is not enabled if we find a negative value, i.e., "0" or...
    dnt === '0' ||
    // ...null...
    dnt === null ||
    // ...or "unspecified" (Firefox)...
    dnt === 'unspecified' ||
    // ...or DNT is undefined and therefore not supported (Safari).
    dnt === undefined
  ) {
    return false
  }
  // If we don't know, assume DNT is enabled (default to privacy)
  return true
}

export async function getOrSetDoNotTrack(): Promise<boolean> {
  let doNotTrack: boolean | null | undefined = null
  if (typeof chrome !== 'undefined' && chrome.storage?.local) {
    doNotTrack = await localStorage.getSingle(STORAGE_KEY.METRICS_OPT_OUT)
  }
  if (doNotTrack === null || doNotTrack === undefined) {
    doNotTrack = getBrowserDoNotTrackSetting()
    // If doNotTrack is undefined here, it means it was called from within
    // the background-script service worker, which only has access to
    // WorkerNavigator. WorkerNavigator does not have the doNotTrack property.
    // We should only set METRICS_OPT_OUT from the browser, when we have an
    // actual value for it.
    if (doNotTrack !== null && doNotTrack !== undefined) {
      if (typeof chrome !== 'undefined' && chrome.storage?.local) {
        await localStorage.set({
          [STORAGE_KEY.METRICS_OPT_OUT]: doNotTrack,
        })
      }
    }
  }
  if (doNotTrack === null || doNotTrack === undefined) {
    return false
  }
  return doNotTrack
}
