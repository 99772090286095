import React from 'react'

import { TableCell } from '@mui/material'

import type { Timing as TimingType } from '..'

export default function Timing({ timing }: { timing: TimingType | null }) {
  if (!timing) {
    return <TableCell>-</TableCell>
  }
  return (
    <TableCell>
      <div>{timing.created_at}</div>
      <div>
        <a href={timing.url} target="_blank" rel="noreferrer">
          {timing.name}
        </a>
      </div>
    </TableCell>
  )
}
