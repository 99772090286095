/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: any; output: any; }
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: any; output: any; }
};

export type AdminUser = {
  __typename?: 'AdminUser';
  anonymousUserIds: Array<Scalars['String']['output']>;
  attributions: Scalars['JSONString']['output'];
  email: Scalars['String']['output'];
  hasActivePlan: Scalars['Boolean']['output'];
  id: Maybe<Scalars['ID']['output']>;
  initialAttributions: Scalars['JSONString']['output'];
  name: Maybe<Scalars['String']['output']>;
  planExpiresOn: Maybe<Scalars['DateTime']['output']>;
  progress: Scalars['JSONString']['output'];
  registeredOn: Scalars['DateTime']['output'];
  registeredWith: Scalars['String']['output'];
  stripeCustomerId: Maybe<Scalars['String']['output']>;
  twofaMethod: Maybe<Scalars['String']['output']>;
};

export type AdminUserInput = {
  id: Scalars['String']['input'];
};

export type AdminUsersInput = {
  direction: Direction;
  orderBy: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
  search: InputMaybe<Scalars['String']['input']>;
};

export enum Direction {
  Asc = 'asc',
  Desc = 'desc'
}

export type QueryAdmin = {
  __typename?: 'QueryAdmin';
  user: Maybe<AdminUser>;
  users: Maybe<Users>;
};


export type QueryAdminUserArgs = {
  input: AdminUserInput;
};


export type QueryAdminUsersArgs = {
  input: AdminUsersInput;
};

export type Users = {
  __typename?: 'Users';
  count: Scalars['Int']['output'];
  items: Array<AdminUser>;
};

export type UserQueryVariables = Exact<{
  input: AdminUserInput;
}>;


export type UserQuery = { __typename?: 'QueryAdmin', user: { __typename?: 'AdminUser', anonymousUserIds: Array<string>, attributions: any, email: string, hasActivePlan: boolean, id: string | null, initialAttributions: any, name: string | null, planExpiresOn: any | null, progress: any, registeredOn: any, registeredWith: string, stripeCustomerId: string | null, twofaMethod: string | null } | null };

export type UsersQueryVariables = Exact<{
  input: AdminUsersInput;
}>;


export type UsersQuery = { __typename?: 'QueryAdmin', users: { __typename?: 'Users', count: number, items: Array<{ __typename?: 'AdminUser', email: string, id: string | null, name: string | null, planExpiresOn: any | null, registeredOn: any }> } | null };


export const UserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"User"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AdminUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"anonymousUserIds"}},{"kind":"Field","name":{"kind":"Name","value":"attributions"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"hasActivePlan"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"initialAttributions"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"planExpiresOn"}},{"kind":"Field","name":{"kind":"Name","value":"progress"}},{"kind":"Field","name":{"kind":"Name","value":"registeredOn"}},{"kind":"Field","name":{"kind":"Name","value":"registeredWith"}},{"kind":"Field","name":{"kind":"Name","value":"stripeCustomerId"}},{"kind":"Field","name":{"kind":"Name","value":"twofaMethod"}}]}}]}}]} as unknown as DocumentNode<UserQuery, UserQueryVariables>;
export const UsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Users"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AdminUsersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"planExpiresOn"}},{"kind":"Field","name":{"kind":"Name","value":"registeredOn"}}]}}]}}]}}]} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;