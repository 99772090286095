import React, { ReactNode } from 'react'

import { TableCell, TableRow } from '@mui/material'

export default function Item({
  children,
  label,
}: {
  children?: ReactNode
  label: string
}) {
  if (!children) {
    return null
  }

  return (
    <TableRow>
      <TableCell sx={{ fontWeight: 'bold', verticalAlign: 'top' }}>
        {label}
      </TableCell>
      <TableCell>{children}</TableCell>
    </TableRow>
  )
}
