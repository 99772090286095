import React from 'react'

import clsx from 'clsx'

import ActionButton, {
  ActionButtonIcon,
  ActionButtonSize,
  ActionButtonType,
} from '$extensionSrc/components/ActionButton'

import styles from './RecommendationCardTopBanner.module.scss'

export default function RecommendationCardTopBanner({
  title,
  detail,
  footerText,
  footerLink,
  onClickClose,
}: {
  title: string
  detail: string
  footerText?: string
  footerLink?: string
  onClickClose: () => void
}) {
  return (
    <div className={styles.RecommendationCardTopBanner}>
      <div className={clsx(styles.bannerHeader, 'heading3 bold black')}>
        <span>{title}</span>
        <ActionButton
          ariaLabel="close banner"
          className={styles.closeButton}
          iconName={ActionButtonIcon.CANCEL}
          iconOnly
          size={ActionButtonSize.EXTRA_SMALL}
          type={ActionButtonType.TERTIARY}
          onClick={onClickClose}
        />
      </div>
      <div className="body2 black">{detail}</div>
      {footerText && (
        <div className={clsx(styles.footer, 'body3', 'darker-gray')}>
          <span className="bold">{footerText}</span>
          {footerLink && (
            <a href={footerLink} target="_parent">
              [Learn more]
            </a>
          )}
        </div>
      )}
    </div>
  )
}
