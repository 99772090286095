import React, { useMemo } from 'react'

import FbUnfriendIcon from '$extensionSrc/icons/fb-unfriend-icon.svg'

import styles from './UnfriendingCount.module.scss'

export default function UnfriendingCount({
  unfriended,
}: {
  unfriended: Record<string, true>
}) {
  const text = useMemo(
    () =>
      Object.keys(unfriended).length === 1
        ? `1 profile unfriended`
        : `${Object.keys(unfriended).length} profiles unfriended`,
    [unfriended],
  )

  return (
    <div className={styles.unfriendingCount}>
      <div className={styles.icon}>
        <FbUnfriendIcon />
      </div>
      <div className="body3 darkest-gray">{text}</div>
    </div>
  )
}
