/* eslint-disable no-console */

import {
  BrowserClient,
  defaultStackParser,
  ErrorEvent,
  getDefaultIntegrations,
  makeFetchTransport,
  Scope,
} from '@sentry/browser'

import { STORAGE_KEY } from './enums'
import * as localStorage from './localStorage'
import BuildConfig from '../buildConfig'
import { SENTRY_DSN } from '../consts/configConsts'
import { getAnonymousUserId } from '../metrics/idGetSet'

let metricsOptOut: boolean | null = null
let sentryScope

/**
 * Set up all logging services, including some shorthand options for
 * configuration.
 */

export function sentrySanitizeProperties(event: ErrorEvent) {
  const sanitizedEvent: ErrorEvent = {
    type: undefined, // required in API; should be overwritten by `event` arg
  }

  const eventAttributesAllowlist = new Set([
    'breadcrumbs',
    'contexts',
    'debug_meta',
    'environment',
    'event_id',
    'exception',
    'level',
    'message',
    'platform',
    'release',
    'sdk',
    'sdkProcessingMetadata',
    'tags',
    'timestamp',
  ])

  Object.keys(event).forEach((key) => {
    if (eventAttributesAllowlist.has(key)) {
      sanitizedEvent[key] = event[key]
    }
  })

  // Handle some nested properties, where we only want to keep a subset
  const eventAllowlistNested = [
    ['request', 'url'],
    ['request', 'headers'],
    ['user', 'id'],
    ['user', 'timezone'],
  ]

  eventAllowlistNested.forEach(([key0, key1]) => {
    if (event?.[key0]?.[key1]) {
      sanitizedEvent[key0] = sanitizedEvent[key0] || {}
      sanitizedEvent[key0][key1] = event[key0][key1]
    }
  })

  if (BuildConfig.REACT_APP_ENV === 'production') {
    // Only keep the origin of event.request.url to guard against
    // accidentally logging user profiles (e.g. facebook.com/triketora)
    // and thus exposing who is using the product.
    if (event?.request?.url) {
      sanitizedEvent.request = sanitizedEvent.request || {}
      try {
        const fullUrl = new URL(event.request.url)
        sanitizedEvent.request.url = fullUrl.origin
      } catch {
        // pass
      }
    }
  }

  return sanitizedEvent
}

export async function initSentry() {
  const integrations = getDefaultIntegrations({}).filter(
    (defaultIntegration) =>
      !['BrowserApiErrors', 'Breadcrumbs', 'GlobalHandlers'].includes(
        defaultIntegration.name,
      ),
  )

  const client = new BrowserClient({
    beforeSend: (event) => {
      if (metricsOptOut) {
        // skip reporting if user has opted out
        return null
      }
      return sentrySanitizeProperties(event)
    },
    dsn: SENTRY_DSN,
    environment: BuildConfig.REACT_APP_ENV,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations,
    ignoreErrors: [
      /* eslint-disable max-len */
      /^Cannot access contents of the page. Extension manifest must request permission to access the respective host.$/,
      /^Response not successful: Received status code (4..|5..)$/,
      /^No host permissions for cookies at url: "https:\/\/app.blockpartyapp.com\/".$/,
      /^ResizeObserver loop completed with undelivered notifications.$/,
      /* eslint-enable max-len */
    ],
    release: chrome.runtime.getManifest().version,
  })

  sentryScope = new Scope()
  sentryScope.setClient(client)
  client.init()

  // Timezone is a very nifty way to detect country
  // https://stackoverflow.com/questions/48278024/how-to-detect-browser-country-in-client-site
  // This is what we use as a proxy for location instead of IP addresses
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const id = await getAnonymousUserId()
  sentryScope.setUser({ id, timezone })
}

export function optOutTracking() {
  metricsOptOut = true
}

export async function optInTracking() {
  metricsOptOut = false
}

export async function syncMetricsOptOut() {
  function handleOptOutValue(value: boolean | undefined) {
    if (value) {
      optOutTracking()
    } else {
      optInTracking()
    }
  }

  await localStorage
    .getSingle(STORAGE_KEY.METRICS_OPT_OUT)
    .then(handleOptOutValue)

  localStorage.setupListener((changes) => {
    if (STORAGE_KEY.METRICS_OPT_OUT in changes) {
      handleOptOutValue(changes[STORAGE_KEY.METRICS_OPT_OUT].newValue)
    }
  })
}

export async function initLoggingServices() {
  await initSentry()
}

export function addSentryEntryPointTag(entryPointName: string) {
  if (sentryScope) {
    sentryScope.setTag('entry_point', entryPointName)
  }
}

export function addSentryAutomationTags(machineKey, stageKey) {
  if (sentryScope) {
    sentryScope.setTag('automation.machine_key', machineKey)
    sentryScope.setTag('automation.stage_key', stageKey)
  }
}

type LogType =
  | 'd'
  | 'debug'
  | 'i'
  | 'info'
  | 'w'
  | 'warn'
  | 'e'
  | 'error'
  | 't'
  | 'trace'

/**
 * Print debug info to console, but only in development.
 *
 * Inspiration for console.log wrapper from
 * https://garage.sekrab.com/posts/writing-a-wrapper-for-console-log-for-better-control-in-javascript-part-i
 */
export function _debug(o: any, message?: string, logType?: LogType) {
  if (
    (typeof window !== 'undefined' && window._inDevelopment) ||
    (typeof window === 'undefined' &&
      BuildConfig.REACT_APP_ENV === 'development')
  ) {
    switch (logType) {
      case 'd':
      case 'debug':
        console.debug(
          `%c${message || ' '}`,
          'background: #81cf61; color: #000000',
          o,
        )
        break
      case 'i':
      case 'info':
        console.info(
          `%c${message || ' '}`,
          'background: #15131f; color: #ffffff',
          o,
        )
        break
      case 'w':
      case 'warn':
        console.warn(
          `%c${message || ' '}`,
          'background: #ffc200; color: #000000',
          o,
        )
        break
      case 'e':
      case 'error':
        console.log(
          `%c${message || ' '}`,
          'background: #df3838; color: #ffffff',
          o,
        )
        break
      case 't':
      case 'trace':
        console.trace(
          `%c${message || ' '}`,
          'background: #fd868c; color: #ffffff',
          o,
        )
        break
      default:
        console.log(
          `%c${message || ' '}`,
          'background: #a6a6a6; color: #ffffff',
          o,
        )
    }
  }
}

/*
 * The preferred alternative to console.log.
 */
export function _attn(o: any) {
  if (typeof window !== 'undefined' && window._inDevelopment) {
    console.log(
      '%c * ',
      'background: #ff8a00; font-weight: bold; color: #000000;',
      o,
    )
  }
}

/**
 * Log an error explicitly to Sentry, if needed.
 */
export function logSentryException(error: Error, entryPoint: string) {
  _debug(error, 'logging', 'e')
  if (!metricsOptOut && sentryScope) {
    if (entryPoint) {
      addSentryEntryPointTag(entryPoint)
    }
    sentryScope.captureException(error)
  }
}

/**
 * Log an info message to Sentry, if needed.
 */
export function logSentryMessage(message: string, entryPoint: string) {
  _debug(message, 'logging', 'i')
  if (!metricsOptOut && sentryScope) {
    if (entryPoint) {
      addSentryEntryPointTag(entryPoint)
    }
    sentryScope.captureMessage(message)
  }
}

export function getSentryInitialized() {
  return sentryScope !== undefined && sentryScope !== null
}

declare global {
  interface Window {
    _inDevelopment: boolean
  }
}

// Only enable console debug output in development
if (typeof window !== 'undefined') {
  window._inDevelopment = BuildConfig.REACT_APP_ENV === 'development'
}
