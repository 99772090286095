export const MONTHS = {
  '0': 'January',
  '1': 'February',
  '2': 'March',
  '3': 'April',
  '4': 'May',
  '5': 'June',
  '6': 'July',
  '7': 'August',
  '8': 'September',
  '9': 'October',
  '10': 'November',
  '11': 'December',
}

export function formatMonthDayAndYear(isoString: string): string {
  return new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(isoString))
}

export const dayInMs = 1000 * 60 * 60 * 24

export function getOneWeekAgoString(): string {
  const date = new Date()
  date.setDate(date.getDate() - 7)

  return date.toISOString()
}

export function getOneDayAgoString(): string {
  const date = new Date()
  date.setDate(date.getDate() - 1)

  return date.toISOString()
}

export function getNMonthsAgoTimestamp(n: number): number {
  const date = new Date()
  date.setMonth(date.getMonth() - n)
  return date.getTime()
}

export function isMoreThanOneHourAgo(timestamp: number): boolean {
  if (timestamp) {
    const diffInSeconds = Math.floor(Math.abs(Date.now() - timestamp) / 1000)
    return Math.floor(diffInSeconds / 60) > 60
  }
  return false
}

export function isSameDay(timestampInMs: number): boolean {
  if (timestampInMs) {
    const todaysDate = new Date()
    const inputDate = new Date(timestampInMs)

    return inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)
  }
  return false
}

export function getTimeSinceInSeconds(timestampInMs: number): number {
  if (timestampInMs) {
    const diffInMilliseconds = Date.now() - timestampInMs
    if (diffInMilliseconds > 0) {
      return Math.floor(diffInMilliseconds / 1000)
    }
  }
  return 0
}

export function getMsAsMinutes(milliseconds: number): number {
  if (milliseconds) {
    return Math.floor(milliseconds / 1000 / 60)
  }
  return 0
}

export function getTimeSinceTimestampStr(timestamp: number): string {
  let strForTimestamp
  const diffInMilliseconds = Math.abs(Date.now() - timestamp)
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000)
  const diffInMinutes = Math.floor(diffInSeconds / 60)
  const diffInHours = Math.floor(diffInMinutes / 60)
  const diffInDays = Math.floor(diffInHours / 24)
  const diffInWeeks = Math.floor(diffInDays / 7)
  const diffInMonths = Math.floor(diffInWeeks / 4)
  const diffInYears = Math.floor(diffInDays / 365)
  if (diffInYears > 0) {
    strForTimestamp = `${diffInYears}y`
  } else if (diffInMonths > 0) {
    strForTimestamp = `${diffInMonths}mo`
  } else if (diffInWeeks > 0) {
    strForTimestamp = `${diffInWeeks}w`
  } else if (diffInDays > 0) {
    strForTimestamp = `${diffInDays}d`
  } else if (diffInHours > 0) {
    strForTimestamp = `${diffInHours}h`
  } else if (diffInMinutes > 0) {
    strForTimestamp = `${diffInMinutes}m`
  } else {
    strForTimestamp = `${diffInSeconds}s`
  }

  return strForTimestamp
}

export function getRelativeTimestampStr(timestamp) {
  let strForTimestamp
  const diffInMilliseconds = Math.abs(Date.now() - timestamp)

  const diffInDays = Math.floor(diffInMilliseconds / dayInMs)
  const diffInWeeks = Math.floor(diffInDays / 7)
  const diffInMonths = Math.floor(diffInDays / 30)
  const diffInYears = Math.floor(diffInDays / 365)

  if (diffInYears > 0) {
    strForTimestamp = `${diffInYears}y ago`
  } else if (diffInMonths > 0) {
    strForTimestamp = `${diffInMonths}mo ago`
  } else if (diffInWeeks > 0) {
    strForTimestamp = `${diffInWeeks}w ago`
  } else if (diffInDays > 0) {
    strForTimestamp = `${diffInDays}d ago`
  } else {
    strForTimestamp = 'today'
  }

  return strForTimestamp
}

export function isMoreThanNumMonthsAgo(
  timestamp,
  numMonths,
  fromTimestamp = Date.now(),
) {
  // A negative timestamp (typically a lastReviewedTimestamp value of '-1')
  // should be treated as a null value and not be used for computation here.
  // For the purposes of this function, it is not more than numMonths ago.
  if (timestamp === -1) {
    return false
  }
  const diffInMilliseconds = Math.max(fromTimestamp - timestamp, 0)
  const diffInDays = Math.floor(diffInMilliseconds / dayInMs)

  const diffInMonths = Math.floor(diffInDays / 30)

  return diffInMonths >= numMonths
}

export function isMoreThanNumDaysAgo(timestamp, numDays) {
  const diffInMilliseconds = Math.abs(Date.now() - timestamp)
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24))

  return diffInDays >= numDays
}

// Returns an array of numbers representing the months that have already
// transpired within the provided year
export function getElapsedMonthsByYear(year: string): number[] {
  const currentYear = new Date().getFullYear()

  if (year === currentYear.toString()) {
    const currentMonth = new Date().getMonth()
    return [...Array(currentMonth + 1).keys()].map((i) => i)
  }

  // all months
  return [...Array(12).keys()].map((i) => i)
}

export enum OLDER_THAN_OPTION {
  NOW = 'all',
  ONE_MONTH = '1',
  TWO_MONTHS = '2',
  THREE_MONTHS = '3',
  CHOOSE_MONTH_YEAR = 'custom',
}

export function getOlderThanMonthYear(
  dateOption: OLDER_THAN_OPTION,
  sinceMonth: string,
  sinceYear: string,
): [number, number] {
  const currentDate = new Date()

  switch (dateOption) {
    case OLDER_THAN_OPTION.NOW:
      currentDate.setMonth(currentDate.getMonth() + 1)
      break
    case OLDER_THAN_OPTION.ONE_MONTH:
      currentDate.setDate(0)
      break
    case OLDER_THAN_OPTION.TWO_MONTHS:
      currentDate.setMonth(currentDate.getMonth() - 2)
      break
    case OLDER_THAN_OPTION.THREE_MONTHS:
      currentDate.setMonth(currentDate.getMonth() - 3)
      break
    default:
      currentDate.setMonth(currentDate.getMonth() + 1)
      break
  }

  if (dateOption !== OLDER_THAN_OPTION.CHOOSE_MONTH_YEAR) {
    return [currentDate.getMonth(), currentDate.getFullYear()]
  }

  return [parseInt(sinceMonth, 10), parseInt(sinceYear, 10)]
}
