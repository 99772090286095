import React, { ReactNode } from 'react'

import parse, { DOMNode, Element, domToReact } from 'html-react-parser'
import showdown from 'showdown'

import { Recommendation, RecommendationKeyEnum } from './recommendationTypes'
import SidebarLink from '../components/SidebarLink'
import { ConcernTag } from '../utils/enums'

const converter = new showdown.Converter()

function parseReplaceSidebarLink(domNode: Element): JSX.Element | null {
  if (domNode.name === 'a' && domNode.children) {
    return (
      <SidebarLink href={domNode.attribs.href}>
        {domToReact(domNode.children as DOMNode[])}
      </SidebarLink>
    )
  }
  return null
}

function convertYamlMarkdownToReact(
  rawMarkdownText: string | undefined | null,
): ReactNode {
  if (!rawMarkdownText) {
    return null
  }

  return parse(converter.makeHtml(rawMarkdownText), {
    replace: parseReplaceSidebarLink,
  })
}

export type Step = { id: number; description: ReactNode }

export default class RecommendationDisplayWrapper {
  recommendation: Recommendation

  topConcernTag: ConcernTag

  constructor(recommendation: Recommendation, topConcernTag: ConcernTag) {
    this.recommendation = recommendation
    this.topConcernTag = topConcernTag
  }

  get accountTypes(): string | string[] | undefined {
    return this.recommendation.accountTypes
  }

  get bodyTitle(): ReactNode {
    return convertYamlMarkdownToReact(this.recommendation.bodyTitle)
  }

  get canUndo(): boolean {
    return this.recommendation.canUndo
  }

  get concernTags(): ConcernTag[] {
    return this.recommendation.concernTags as ConcernTag[]
  }

  get confirmTaskButtonText(): string | null {
    return this.recommendation.confirmTaskButtonText || null
  }

  get continuingTaskButtonText(): string | null {
    return this.recommendation.continuingTaskButtonText || null
  }

  get customActionDescription(): string | undefined {
    return this.recommendation.customActionDescription
  }

  get explanationText(): ReactNode {
    if (this.recommendation.explanationTextByConcernTag) {
      let markdownText =
        this.recommendation.explanationTextByConcernTag[this.topConcernTag]
      if (!markdownText) {
        markdownText =
          this.recommendation.explanationTextByConcernTag[
            this.recommendation.concernTags[0]
          ]
      }

      return convertYamlMarkdownToReact(markdownText)
    }
    return convertYamlMarkdownToReact(this.recommendation.explanationTextOld)
  }

  get hasAutomatedGather(): boolean {
    return this.recommendation.hasAutomatedGather || false
  }

  get isAutomated(): boolean {
    return this.recommendation.isAutomated
  }

  get isPhoto(): boolean {
    return this.recommendation.isPhoto || false
  }

  get isTask(): boolean {
    return this.recommendation.isTask || false
  }

  get key(): RecommendationKeyEnum {
    return this.recommendation.key
  }

  get recommendationCardType(): string {
    return this.recommendation.recommendationCardType
  }

  get recommendationTitle(): string {
    return this.recommendation.recommendationTitle
  }

  get deeplinkShareUrl(): string | undefined {
    return this.recommendation.deeplinkShareUrl
  }

  get settings(): { key: string; description: ReactNode }[] {
    if (!this.recommendation.settings) {
      return []
    }

    return this.recommendation.settings.map((setting) => ({
      key: setting.key,
      description: convertYamlMarkdownToReact(setting.description),
    }))
  }

  get steps(): Step[] {
    if (!this.recommendation.steps) {
      return []
    }

    return this.recommendation.steps.map((step, i) => ({
      id: i,
      description: convertYamlMarkdownToReact(step.description),
    }))
  }

  get taskButtonText(): string | null {
    return this.recommendation.taskButtonText || null
  }

  get taskContext(): string[] {
    return this.recommendation.taskContext || []
  }

  get taskTitle(): string | null {
    return this.recommendation.taskTitle || null
  }

  get taskUpdatedText(): string | null {
    return this.recommendation.taskUpdatedText || null
  }

  get taskWarningText(): string | null {
    return this.recommendation.taskWarningText || null
  }

  get shortTitle(): string | null {
    return this.recommendation.shortTitle || null
  }
}
