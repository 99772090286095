import React from 'react'

import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import Timing from './Timing'
import type { Priority, Stats } from '../IssueResolution'

export default function Tile({
  priority,
  stats,
}: {
  priority: Priority
  stats: Stats
}) {
  return (
    <Grid item xs={6}>
      <Card>
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography align="center" component="div" variant="h5">
                {priority}
              </Typography>
            </Grid>
            <Grid item>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} sx={{ fontWeight: '600' }}>
                        Resolution
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Mean</TableCell>
                      <TableCell>{stats.resolution.mean || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Median</TableCell>
                      <TableCell>{stats.resolution.median || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Max</TableCell>
                      <TableCell>{stats.resolution.max || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Min</TableCell>
                      <TableCell>{stats.resolution.min || '-'}</TableCell>
                    </TableRow>
                  </TableBody>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} sx={{ fontWeight: '600' }}>
                        Counts
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Completed</TableCell>
                      <TableCell>{stats.completed}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Uncompleted</TableCell>
                      <TableCell>{stats.uncompleted}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Assigned</TableCell>
                      <TableCell>{stats.assigned}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Unassigned</TableCell>
                      <TableCell>{stats.unassigned}</TableCell>
                    </TableRow>
                  </TableBody>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} sx={{ fontWeight: '600' }}>
                        Timing
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Newest</TableCell>
                      <Timing timing={stats.newest} />
                    </TableRow>
                    <TableRow>
                      <TableCell>Oldest</TableCell>
                      <Timing timing={stats.oldest} />
                    </TableRow>
                    <TableRow>
                      <TableCell>Oldest Uncompleted</TableCell>
                      <Timing timing={stats.oldest_uncompleted} />
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
