import React, { useCallback, useState } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material'

import Details from './Details'
import { UsersQuery } from '../../../../__generated__/graphql'
import { getSqlDateTimeFromIsoString } from '../../../../utils/dateHelpers'

const MIXPANEL_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://mixpanel.com/project/3376648/view/3882860/app/profile#distinct_id='
    : 'https://mixpanel.com/project/3014392/view/3532696/app/profile#distinct_id='

export default function Row({
  user,
}: {
  user: Exclude<UsersQuery['users'], null | undefined>['items'][0]
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleClick = useCallback(
    () => setIsOpen((currentIsOpen) => !currentIsOpen),
    [],
  )

  // The server-side GraphQL type for AdminUser is incorrect -- id should
  // always be present, not just email
  if (!user?.id) {
    return null
  }
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton onClick={handleClick} size="small">
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <a
            href={`${MIXPANEL_URL}${user.id}`}
            rel="noreferrer"
            target="_blank"
          >
            {user.id}
          </a>
        </TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.name}</TableCell>
        <TableCell>{getSqlDateTimeFromIsoString(user.registeredOn)}</TableCell>
        <TableCell>{getSqlDateTimeFromIsoString(user.planExpiresOn)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} sx={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Details id={user.id} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
