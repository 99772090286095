import { useEffect, useState } from 'react'

import { EXTENSION_MESSAGE_TYPES, PLATFORM } from '../../utils/enums'
import * as messages from '../../utils/messages'

function hostnameMatches(hostname: string, incomingDomain: string): boolean {
  const domain = incomingDomain.replace('.', '\\.')
  const regexString = `^(.+\\.)?${domain}`
  return hostname.match(new RegExp(regexString)) !== null
}

export function getPlatformFromUrlString(
  urlString: string | undefined,
): PLATFORM | null {
  if (!urlString) {
    return null
  }
  let hostname: string
  try {
    hostname = new URL(urlString).hostname
  } catch {
    return null
  }
  if (hostnameMatches(hostname, 'facebook.com')) {
    return PLATFORM.FACEBOOK
  } else if (hostnameMatches(hostname, 'google.com')) {
    return PLATFORM.GOOGLE
  } else if (hostnameMatches(hostname, 'instagram.com')) {
    return PLATFORM.INSTAGRAM
  } else if (hostnameMatches(hostname, 'linkedin.com')) {
    return PLATFORM.LINKEDIN
  } else if (hostnameMatches(hostname, 'reddit.com')) {
    return PLATFORM.REDDIT
  } else if (hostnameMatches(hostname, 'strava.com')) {
    return PLATFORM.STRAVA
  } else if (
    hostnameMatches(hostname, 'twitter.com') ||
    hostnameMatches(hostname, 'x.com')
  ) {
    return PLATFORM.TWITTER
  } else if (hostnameMatches(hostname, 'venmo.com')) {
    return PLATFORM.VENMO
  } else if (hostnameMatches(hostname, 'youtube.com')) {
    return PLATFORM.YOUTUBE
  }
  return null
}

export function usePlatform(): PLATFORM | null | undefined {
  const [platform, setPlatform] = useState<PLATFORM | null | undefined>()

  useEffect(() => {
    const query = async () => {
      let p: PLATFORM | null | undefined

      // Used by sidebar and content-script-inject-dom
      const message = messages.createMessage(
        EXTENSION_MESSAGE_TYPES.GET_CURRENT_TAB,
      )
      const response = await chrome.runtime.sendMessage(message)
      if (response?.tab?.url) {
        p = getPlatformFromUrlString(response.tab.url)
        setPlatform(p)
      }
    }

    query()
  }, [])

  return platform
}
