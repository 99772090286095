import React from 'react'

import { ApolloProvider } from '@apollo/client'

import Users from './Users'
import { client } from '../../apollo'
import MaterialUiProvider from '../MaterialUiProvider'

export default function UsersPage({ cspNonce }: { cspNonce: string }) {
  return (
    <ApolloProvider client={client}>
      <MaterialUiProvider cspNonce={cspNonce}>
        <h1>Users</h1>
        <Users />
      </MaterialUiProvider>
    </ApolloProvider>
  )
}
