export function getCookie(
  details: chrome.cookies.CookieDetails,
): Promise<chrome.cookies.Cookie | null> {
  return chrome.cookies.get(details)
}

export function setCookie(details: chrome.cookies.SetDetails) {
  return chrome.cookies.set(details)
}

export async function getCookieIgnoreHostPermissionErrors(
  details: chrome.cookies.CookieDetails,
): Promise<chrome.cookies.Cookie | null> {
  let cookie: chrome.cookies.Cookie | null = null

  if (typeof chrome === 'undefined' || !chrome.cookies) {
    return cookie
  }

  try {
    cookie = await chrome.cookies.get(details)
  } catch (error) {
    if (
      error &&
      error.message.startsWith('No host permissions for cookies at url')
    ) {
      // ignore
    } else {
      throw error
    }
  }

  return cookie
}

export async function getCookieStoreFromTabId(
  tabId: number,
): Promise<chrome.cookies.CookieStore | null> {
  const stores = await chrome.cookies.getAllCookieStores()

  for (const store of stores) {
    if (store.tabIds.includes(tabId)) {
      return store
    }
  }
  return null
}

export async function removeCookieIgnoreHostPermissionErrors(
  details: chrome.cookies.CookieDetails,
): Promise<void> {
  if (typeof chrome === 'undefined' || !chrome.cookies) {
    return
  }

  try {
    await chrome.cookies.remove(details)
  } catch (error) {
    if (
      error &&
      error.message.startsWith('No host permissions for cookies at url')
    ) {
      // ignore
    } else {
      throw error
    }
  }
}
