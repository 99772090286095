/**
 * Framework for interrupting processes.
 *
 * When we call `requestGlobalInterrupt`, we set a flag that causes
 * `throwIfGlobalInterruptRequested` to throw an exception and that causes the
 * promises returned by `getGlobalInterruptPromise` to resolve, wherever those
 * methods are used.
 *
 * This allows us to interrupt running processes from the top-level of our
 * scripts, e.g., from a listener that waits for a particular message to be
 * received.
 */
import { _debug } from '../utils/logging'

export class GlobalInterrupt extends Error {
  constructor(message = null) {
    super(message)
    this.name = 'GlobalInterrupt'
  }
}

// Setup internal variables tracking if interrupt has been requested

interface GlobalInterruptStatus {
  promise: Promise<GlobalInterrupt>
  reason: string | null
  requested: boolean
  resolvePromise: () => void
}

const globalInterruptStatus: GlobalInterruptStatus = {
  promise: null,
  reason: null,
  requested: false,
  resolvePromise: () => {},
}

function initializeGlobalInterruptHandling() {
  globalInterruptStatus.reason = null
  globalInterruptStatus.requested = false
  globalInterruptStatus.promise = new Promise((resolve) => {
    globalInterruptStatus.resolvePromise = () =>
      resolve(new GlobalInterrupt(globalInterruptStatus.reason))
  })
}

initializeGlobalInterruptHandling()

// Methods for requesting interrupts or resetting the system

export function requestGlobalInterrupt(reason: string): void {
  _debug(`requested: ${reason}`, 'global interrupt', 'd')
  globalInterruptStatus.reason = reason
  globalInterruptStatus.requested = true
  if (globalInterruptStatus.resolvePromise) {
    globalInterruptStatus.resolvePromise()
  }
}

export function resetGlobalInterruptRequest(): void {
  _debug('request reset', 'global interrupt', 'd')
  if (globalInterruptStatus.requested) {
    initializeGlobalInterruptHandling()
  }
}

// Methods for propagating interrupts into other processes

export function getGlobalInterruptPromise(): Promise<GlobalInterrupt> {
  return globalInterruptStatus.promise
}

export function throwIfGlobalInterruptRequested(): void {
  if (globalInterruptStatus.requested) {
    throw new GlobalInterrupt(globalInterruptStatus.reason)
  }
}

export function throwIfGlobalInterruptRequestedWrapper<T extends Array<any>, U>(
  fn: (...args: T) => U,
): (...args: T) => U {
  return (...args) => {
    throwIfGlobalInterruptRequested()
    return fn(...args)
  }
}
