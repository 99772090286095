import { STORAGE_KEY } from './enums'
import * as localStorage from './localStorage'

/**
 * Used by the extension code.
 */
export async function getUserAccountIdWebExtensionStorageApi(): Promise<
  string | undefined
> {
  const currentUser = await localStorage.getSingle(STORAGE_KEY.CURRENT_USER)
  if (currentUser && currentUser.id) {
    return currentUser.id
  }
  return undefined
}

/**
 * Used by the extension server frontend; the userId value is
 * set in `base.html` via a Jinja2 template.
 */
function getUserAccountIdFromWindow(): string | undefined {
  // Used for server fe, where we pass the id into the window via Jinja2
  // See base.html
  if (typeof window !== 'undefined') {
    // @ts-ignore
    return window.userId
  }
  return undefined
}

export async function getUserAccountId(): Promise<string | undefined> {
  let userId
  if (typeof chrome !== 'undefined' && chrome.storage) {
    // proxy for if we're in the extension
    userId = await getUserAccountIdWebExtensionStorageApi()
  } else if (typeof window !== 'undefined') {
    userId = getUserAccountIdFromWindow()
  }
  return userId
}
