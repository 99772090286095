import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'

const cache = new InMemoryCache()

const link = createHttpLink({
  headers: {
    'X-Admin-Api-Key': process.env.ADMIN_API_KEY || '',
  },
  uri: `${process.env.EXTENSION_API_ORIGIN}/api/graphql-admin/`,
})

if (process.env.NODE_ENV !== 'production') {
  loadDevMessages()
  loadErrorMessages()
}

export const client = new ApolloClient({
  cache,
  // In our case, we always want updated data, so we default to no-cache.
  // This means "fetch from server, and don't even bother to update the cache".
  defaultOptions: { query: { fetchPolicy: 'no-cache' } },
  link,
})
